import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import logo from '../images/logo/logo.png'





 const MosaikLayout = ({children}) => {
    const navigation = [
      { name: 'My Modules', href: '/mosaik-modules' },
      { name: 'Marketplace', href: '/mosaik-marketplace' },
      { name: 'Mosaik', href: '/mosaik' },
      { name: 'Contact Form Submissions', href: '/contactformsubmissions' },
    ];

    return (
      <header className="absolute inset-x-0 top-0 z-50 bg-gray-900 shadow-lg h-16">
        <nav aria-label="Global" className="flex items-center justify-between p-4 lg:px-8">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt="Logo"
                src={logo}
                className="h-10 w-auto"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-300 hover:bg-gray-800 transition duration-300"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-10">
            {navigation.map((item) => (
              <Link key={item.name} to={item.href} className="text-sm font-medium text-gray-300 hover:text-white transition duration-300">
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link to="/mosaik" className="text-sm font-medium text-gray-300 hover:text-white transition duration-300">
              Log in
            </Link>
          </div>
        </nav>
      </header>
    );
 }

 export default MosaikLayout