import React from 'react';
import background from '../../images/backgrounds/neuralnetwork.jpg';
import FrontpageLayout from '../FrontpageLayout';

export default function Home() {
  return (
    <>
      <FrontpageLayout />
      <div className="relative isolate overflow-hidden h-screen flex items-center justify-center p-6 sm:p-14">
        <img
          alt=""
          src={background}
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div className="gradient-overlay"></div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl py-20 sm:py-32 lg:py-40 text-center">
          <h1 className="text-3xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Do it better, faster.
          </h1>
          <div className="w-full flex justify-center items-center">
            <p className="w-3/4 sm:w-1/2 border-t-2 border-white mt-6 shadow"></p>
          </div>
          <p className="mt-6 text-lg sm:text-xl lg:text-2xl leading-8 text-gray-200">
            Generative AI is poised to revolutionize the way we approach knowledge work across all industries and most professions. You can benefit from everything advanced AI has to offer - now, not tomorrow.
          </p>
          <div className="mt-6 flex flex-col sm:flex-row items-center justify-center gap-x-6">
            <a
              href="/about#contact"
              className="mb-4 sm:mb-0 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-opacity-80 bg-opacity-60"
            >
              Reach out Now.
            </a>
            {/* 
            <a href="/bookworkshop" className="text-sm font-semibold leading-6 text-white hover:text-indigo-300">
              Book a workshop <span aria-hidden="true">→</span>
            </a>*/}
          </div>
          <p className="mt-6 text-lg sm:text-2xl leading-8 text-white bold">
            The world is changing. Adapt to the AI revolution.
          </p>
          <div className="w-full flex justify-center items-center">
            <p className="w-3/4 sm:w-1/2 border-t-2 border-white mt-6 shadow"></p>
          </div>
          {/* 
          <div className="mt-6 sm:mt-8 flex justify-center">
            <div className="relative rounded-full px-4 py-2 text-sm leading-6 text-gray-200 ring-1 ring-white/10 hover:ring-white/20">
              Stay in the loop and{' '}
              <a href="/newsletter" className="font-semibold text-white">
                Follow our Newsletter <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>*/}
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </>
  );
}
