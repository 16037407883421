import axios from 'axios';

// Create an instance of axios
const apiClient = axios.create();

// Set the Authorization header for all requests
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');  // Assuming you stored the JWT in local storage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Intercept responses to handle 401 errors
apiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    // Redirect to login page if 401 Unauthorized
    window.location.href = '/login';  // Adjust the path to your login page if necessary
  }
  return Promise.reject(error);
});

export default apiClient;
