import React, { useEffect, useState } from 'react';

function ContactSubmissionsTable({api_url}) {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all submissions when the component mounts
  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch(`${api_url}/api/contact-form-submissions/`);
        const data = await response.json();
        console.log(data)
        setSubmissions(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching submissions:', error);
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  // Delete a submission
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this submission?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${api_url}/api/contact-form-submissions/${id}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Update state to remove the deleted submission
        setSubmissions(submissions.filter((submission) => submission.id !== id));
      } else {
        console.error('Error deleting submission');
      }
    } catch (error) {
      console.error('Error deleting submission:', error);
    }
  };

  if (loading) {
    return <div>Loading submissions...</div>;
  }

  if (submissions.length === 0) {
    return <div>No submissions found.</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto bg-white rounded-md shadow-lg">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="px-4 py-2">First Name</th>
            <th className="px-4 py-2">Last Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Phone Number</th>
            <th className="px-4 py-2">Message</th>
            <th className="px-4 py-2">Submitted At</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission) => (
            <tr key={submission.id} className="border-t">
              <td className="px-4 py-2">{submission.first_name}</td>
              <td className="px-4 py-2">{submission.last_name}</td>
              <td className="px-4 py-2">{submission.email}</td>
              <td className="px-4 py-2">{submission.phone_number || 'N/A'}</td>
              <td className="px-4 py-2">{submission.message}</td>
              <td className="px-4 py-2">{new Date(submission.submitted_at).toLocaleString()}</td>
              <td className="px-4 py-2">
                <button
                  onClick={() => handleDelete(submission.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContactSubmissionsTable;
