import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import FrontpageLayout from '../FrontpageLayout';
import Footer from '../Footer';

export default function ServicesLandingPage() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const services = [
    {
      title: 'AI Consulting Services',
      description: 'Unlock the potential of AI with our tailored workshops and hands-on guidance.',
      furtherDetails: [
        'Tailored Workshops: We design custom sessions specifically for your industry\'s needs, ensuring your team gains practical AI knowledge.',
        'Process Analysis: Together with you, we dive into your current operations to uncover opportunities for AI integration.',
        'Proof of Concept: We develop & showcase possible AI solutions that meet your business requirements and deliver tangible results.',
      ],
      callToActionLink: 'Reach Out →'
    },
    {
      title: 'AI Solution Development',
      description: 'Create tailored AI solutions that meet your business needs, from concept to deployment.',
      furtherDetails: [
        'Custom AI Solutions: We work alone or with your team to design AI applications tailored to your specific needs.',
        'End-to-End Development: From initial concept through to deployment, we manage the entire development lifecycle.',
        'Seamless Integration: Ensure your new AI solutions work harmoniously with existing systems and processes.',
      ],
      callToActionLink: 'Reach Out →'
    },
    {
      title: 'Workshop: The Rise of Generative AI',
      description: 'Gain insights into the technology, market dynamics, and future trends of AI.',
      furtherDetails: [
        'Technological Background: Understand the fundamentals of AI, including the differences between generative AI, general AI, and machine learning. Explore practical use cases for each.',
        'Market Dynamics: Learn about the latest AI models, their unique capabilities, and how companies across industries are leveraging them for competitive advantage.',
        'Hands-On Exercise: Use Mosaik, our no-code platform, to build your own simple AI solution, putting theory into practice.',
      ],
      callToActionLink: 'Reach Out →'
    },
  ];
  

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? services.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === services.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <FrontpageLayout />
      <div className="relative isolate overflow-hidden neural-network-background min-h-screen pt-20 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          {/* Left side */}
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 ">
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Innovation through AI and Technology
            </h1>

            <p className="mt-6 text-lg leading-8 text-gray-300">
            Enhance your business with practical AI solutions tailored to your needs. We offer workshops, consulting, and seamless AI integration, along with support for product development. Whether you're exploring AI or looking for solid advice on IT strategy and software development, we're here to help you navigate the evolving technology landscape.
            </p>
            {/* 
            <div className="absolute ">
              <a href="#" className="text-sm font-semibold leading-6 text-white">
                Learn More <span aria-hidden="true">→</span>
              </a>
            </div>*/}
          </div>

        <div className="relative mt-12 lg:ml-10 lg:mt-0 lg:flex lg:flex-none w-full">
        <div className="relative w-full lg:w-2/3 flex-none lg:-right-20 min-h-content md:h-[500px]">
        <div className="overflow-hidden relative rounded-lg bg-gradient-to-br from-gray-800 via-gray-900 to-black shadow-lg transform hover:scale-105 transition-transform duration-300 p-8 h-full">
          <h2 className="text-4xl font-extrabold text-white mb-6 tracking-tight">
            {services[currentIndex].title}
          </h2>

          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            {services[currentIndex].description}
          </p>

          {services[currentIndex].furtherDetails && (
            <ul className="text-lg text-gray-400 mb-6 list-disc list-inside space-y-4 mx-12 pb-2">
              {services[currentIndex].furtherDetails.map((detail, index) => {
                const [firstPart, ...rest] = detail.split(':'); // Split at the colon
                return (
                  <li key={index}>
                    <span className="font-bold text-white">{firstPart}:</span> {rest.join(':').trim()}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
            <div className="absolute inset-0 flex items-center justify-between px-4 z-40">
            <button
                onClick={handlePrevClick}
                className="p-3 text-white bg-gray-800 rounded-full hover:bg-gray-700 z-40"
            >
                <ChevronLeftIcon className="h-6 w-6 lg:h-8 lg:w-8" />
            </button>

            <a 
              href="/about#contact" 
              className="absolute z-50 bottom-2 left-2 h-12 w-28 flex items-center justify-center text-indigo-400 border border-indigo-400 rounded-md hover:bg-indigo-400 hover:text-white transition-colors duration-300 ease-in-out font-semibold"
            >
              {services[currentIndex].callToActionLink}
            </a>
            <button
                onClick={handleNextClick}
                className="p-3 text-white bg-gray-800 rounded-full hover:bg-gray-700 z-40"
            >
                <ChevronRightIcon className="h-6 w-6 lg:h-8 lg:w-8" />
            </button>
            </div>

            {/* Pagination Dots */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {services.map((_, idx) => (
                <span
                key={idx}
                className={`h-2 w-2 rounded-full ${
                    currentIndex === idx ? 'bg-white' : 'bg-gray-500'
                }`}
                ></span>
            ))}
            </div>
        </div>
        </div>





        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
