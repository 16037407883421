'use client'
import React, { useState, useEffect } from 'react';
import { XMarkIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import apiClient from '../../apiClient';
import StyleSettings from './component_settings/StyleSettings';
import AISettingsText from './component_settings/AISettingsText';
import AISettingsConversation from './component_settings/AISettingsConversation';
import StructuredDataSettings from './component_settings/StructuredDataSettings';

function ItemSettings({ api_url, handleTrigger, settingItem, handleBlur, isSelectionMode, setIsSelectionMode, setSettingItem, grid, fetchGridItems, isContextSelectionMode, setIsContextSelectionMode, fetchContexts, contexts, setContexts }) {

    const [openCategories, setOpenCategories] = useState({});

    const [itemName, setItemName] = useState(''); // Initialize with the current item name
    const [itemDescription, setItemDescription] = useState(''); // Initialize with the current item name

    const toggleCategory = (category) => {
        setOpenCategories((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    // Synchronize itemName with settingItem.name whenever settingItem changes
    useEffect(() => {
        if (settingItem) {
            setItemName(settingItem.name || '');
            setItemDescription(settingItem.description || '');
            fetchContexts(settingItem.i);
        }
    }, [settingItem]);


    const deleteItem = (item) => {
        if (item) {
            apiClient.delete(`${api_url}/api/${item.item_type}-items/${item.i}/`)
            .then(response => {
                if (response.status === 204) {
                    setSettingItem(null); // Optionally, clear the settings panel
                    console.log(`Item ${item.i} deleted successfully.`);
                    fetchGridItems(grid.id);
                } else {
                    console.error('Failed to delete the item.');
                }
            })
            .catch(error => console.error('Error deleting the item:', error));
        }
    };

    const handleNameChange = (e) => {
        setItemName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setItemDescription(e.target.value);
    };

    const updateItemName = () => {
        if (settingItem && itemName !== settingItem.name) {
            apiClient.patch(`${api_url}/api/${settingItem.item_type}-items/${settingItem.i}/`, { name: itemName })
                .then(response => {
                    setSettingItem(response.data);
                    console.log(`Item ${settingItem.id} name updated successfully.`);
                    fetchGridItems();
                })
                .catch(error => console.error('Error updating the item name:', error));
        }
    };

    const updateItemDescription = () => {
        if (settingItem && itemDescription !== settingItem.description) {
            apiClient.patch(`${api_url}/api/${settingItem.item_type}-items/${settingItem.i}/`, { description: itemDescription })
                .then(response => {
                    setSettingItem(response.data);
                    console.log(`Item ${settingItem.id} description updated successfully.`);
                    fetchGridItems();
                })
                .catch(error => console.error('Error updating the item name:', error));
        }
    };
    const handleRemoveContext = async (contextId) => {
        try {
            await apiClient.delete(`${api_url}/api/contexts/${contextId}/`);
            setContexts(contexts.filter(context => context.id !== contextId));
            console.log(`Context ${contextId} removed successfully.`);
        } catch (error) {
            console.error('Error removing context:', error);
        }
    };

    const handleReorderContext = async (contextId, direction) => {
        try {
            // Find the index of the context to be reordered
            const contextIndex = contexts.findIndex(c => c.id === contextId);
            if (contextIndex === -1) return;
    
            // Determine the new index after reordering
            const swapIndex = direction === 'up' ? contextIndex - 1 : contextIndex + 1;
            if (swapIndex < 0 || swapIndex >= contexts.length) return;
    
            // Create a copy of the contexts array
            const updatedContexts = [...contexts];
    
            // Reorder the contexts in the array
            const [movedContext] = updatedContexts.splice(contextIndex, 1);
            updatedContexts.splice(swapIndex, 0, movedContext);
    
            // Recalculate positions
            updatedContexts.forEach((context, index) => {
                context.position = index + 1;
            });
    
            // Update positions in the backend
            const patchRequests = updatedContexts.map(context => 
                apiClient.patch(`${api_url}/api/contexts/${context.id}/`, {
                    position: context.position
                })
            );
    
            // Await all patch requests
            await Promise.all(patchRequests);
    
            // Update the state with the newly ordered contexts
            setContexts(updatedContexts);
    
        } catch (error) {
            console.error('Error reordering context:', error);
        }
    };
    
    return (
        <div className="p-4 h-full bg-gray-800 rounded-lg shadow-lg relative overflow-y-auto">
            <XMarkIcon
                className="absolute top-2 right-2 h-6 w-6 cursor-pointer text-gray-300 hover:text-white bg-gray-600 rounded-full hover:bg-red-500 transition-colors duration-200"
                onClick={() => setSettingItem(null)}
            />
            <h2 className="text-2xl font-semibold text-white mb-4">Settings</h2>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">Item Name</label>
                <input
                    type="text"
                    value={itemName}
                    onChange={handleNameChange}
                    onBlur={() => updateItemName()}
                    className="mt-1 p-2 bg-gray-700 text-white border border-gray-600 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">Item Description</label>
                <input
                    type="text"
                    value={itemDescription}
                    onChange={handleDescriptionChange}
                    onBlur={() => updateItemDescription()}
                    className="mt-1 p-2 bg-gray-700 text-white border border-gray-600 rounded w-full h-40 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>

            {/* Include the setting components here */}
            <div className="mb-4">
                <div
                    className="p-3 bg-gray-700 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-600 transition-colors duration-200"
                    onClick={() => toggleCategory('style')}
                >
                    <span className="text-white">Style</span>
                    {openCategories['style'] ? (
                        <ChevronUpIcon className="h-5 w-5 text-white" />
                    ) : (
                        <ChevronDownIcon className="h-5 w-5 text-white" />
                    )}
                </div>
                {openCategories['style'] && (
                    <div className="p-3 space-y-2">
                        <StyleSettings />
                    </div>
                )}
            </div>

            {settingItem?.item_type === "text_generation" && (
                <div className="mb-4">
                    <div
                        className="p-3 bg-gray-700 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-600 transition-colors duration-200"
                        onClick={() => toggleCategory('ai_settings_text')}
                    >
                        <span className="text-white">AI Settings</span>
                        {openCategories['ai_settings_text'] ? (
                            <ChevronUpIcon className="h-5 w-5 text-white" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-white" />
                        )}
                    </div>
                    {openCategories['ai_settings_text'] && (
                        <div className="p-3 space-y-2">
                            <AISettingsText
                                settingItem={settingItem}
                                handleBlur={handleBlur}
                                isSelectionMode={isSelectionMode}
                                isContextSelectionMode={isContextSelectionMode}
                                setIsSelectionMode={setIsSelectionMode}
                                setIsContextSelectionMode={setIsContextSelectionMode}
                                handleReorderContext={handleReorderContext}
                                fetchContexts={fetchContexts}
                                contexts={contexts}
                                setContexts={setContexts}
                                handleRemoveContext={handleRemoveContext}
                            />
                        </div>
                    )}
                </div>
            )}

            {settingItem?.item_type === "conversation" && (
                <div className="mb-4">
                    <div
                        className="p-3 bg-gray-700 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-600 transition-colors duration-200"
                        onClick={() => toggleCategory('ai_settings_conversation')}
                    >
                        <span className="text-white">AI Settings</span>
                        {openCategories['ai_settings_conversation'] ? (
                            <ChevronUpIcon className="h-5 w-5 text-white" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-white" />
                        )}
                    </div>
                    {openCategories['ai_settings_conversation'] && (
                        <div className="p-3 space-y-2">
                            <AISettingsConversation
                                settingItem={settingItem}
                                handleBlur={handleBlur}
                                isContextSelectionMode={isContextSelectionMode}
                                setIsContextSelectionMode={setIsContextSelectionMode}
                                handleReorderContext={handleReorderContext}
                                fetchContexts={fetchContexts}
                                contexts={contexts}
                                setContexts={setContexts}
                                handleRemoveContext={handleRemoveContext}
                            />
                        </div>
                    )}
                </div>
            )}

            {settingItem?.item_type === "structured_data" && (
                <div className="mb-4">
                    <div
                        className="p-3 bg-gray-700 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-600 transition-colors duration-200"
                        onClick={() => toggleCategory('structureddata')}
                    >
                        <span className="text-white">Structured Data</span>
                        {openCategories['columns'] ? (
                            <ChevronUpIcon className="h-5 w-5 text-white" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-white" />
                        )}
                    </div>
                    {openCategories['structureddata'] && (
                        <div className="p-3 space-y-2">
                            <StructuredDataSettings
                                api_url={api_url}
                                settingItem={settingItem}
                                handleBlur={handleBlur}
                                handleTrigger={handleTrigger}
                                fetchGridItems={fetchGridItems}
                            />
                        </div>
                    )}
                </div>
            )}

            <div className="mt-8">
                <button
                    className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors duration-200"
                    onClick={() => deleteItem(settingItem)}
                >
                    Delete Item
                </button>
            </div>
        </div>
    );
}

export default ItemSettings;
