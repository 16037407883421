import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests

export const AuthContext = createContext();

export const AuthProvider = ({ children, api_url }) => {
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setAuthToken(token);
      }
      setLoading(false);
    };

    setTimeout(checkAuthToken, 1000);
  }, []);

  const login = (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
  };

  const isAuthenticated = () => {
    return !!authToken;
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${api_url}/api/token/refresh/`, { token: authToken });
      const newToken = response.data.token;
      setAuthToken(newToken);
      localStorage.setItem('authToken', newToken);
      return newToken;
    } catch (error) {
      logout(); // If refreshing fails, log out
      return null;
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ authToken, login, logout, isAuthenticated, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};
