import React from 'react';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';

const TableOverlay = ({ isOpen, handleCloseOverlay, selectedRow }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative bg-gray-900 p-8 rounded-lg shadow-lg w-2/3 max-w-2xl h-3/4 overflow-hidden">
                {/* Close Button */}
                <button
                    onClick={handleCloseOverlay}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {/* Modal Title */}
                <h2 className="text-2xl font-semibold mb-6 text-white">Row Details</h2>

                {/* Scrollable Content */}
                <div className="h-full overflow-y-auto">
                    <div className="mb-4 text-gray-300">
                        <strong>Name:</strong>
                        <ReactMarkdown>{selectedRow.name}</ReactMarkdown>
                    </div>

                    <div className="mb-4 text-gray-300">
                        <strong>Description:</strong>
                        <ReactMarkdown>{selectedRow.description}</ReactMarkdown>
                    </div>

                    <div className="text-gray-300">
                        <strong>Content:</strong>
                        <ReactMarkdown>{selectedRow.text_content}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default TableOverlay;
