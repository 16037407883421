export default function Footer() {
    return (
      <footer className="bg-gray-900 text-white py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Company Info */}
            <div>
              <h3 className="text-lg font-semibold">Modular Technology</h3>
              <p className="mt-4 text-sm text-gray-400">
              Modular Technology (modulartech.ai) is a startup focused on providing an innovative no-code platform for building generative AI applications. Our mission is to make advanced AI accessible to all, enabling users to bring their ideas to life better and faster, regardless of their technical expertise. With a unique emphasis on AI functionalities, our platform empowers a diverse audience—including consultants, educators, marketers, and legal professionals—to create groundbreaking AI solutions.
              </p>
            </div>
            {/* Navigation Links */}
          <div>
            <h3 className="text-lg font-semibold text-center">Navigation</h3>
            <ul className="mt-4 space-y-2 text-center">
              <li><a href="/" className="text-gray-400 hover:text-white">Home</a></li>
              <li><a href="/platform" className="text-gray-400 hover:text-white">Platform</a></li>
              <li><a href="/services" className="text-gray-400 hover:text-white">Services</a></li>
              <li><a href="/about" className="text-gray-400 hover:text-white">About</a></li>
            </ul>
          </div>
            {/* Social Media Links
             */}
            <div>
              <h3 className="text-lg font-semibold">Follow Us</h3>
              <div className="mt-4 flex space-x-6">
                <a href="https://www.linkedin.com/company/modulartech/" className="text-gray-400 hover:text-white">
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M22.23 0h-20.46c-.977 0-1.77.792-1.77 1.77v20.46c0 .978.793 1.77 1.77 1.77h20.46c.977 0 1.77-.792 1.77-1.77v-20.46c0-.978-.793-1.77-1.77-1.77zm-14.539 20.454h-3.614v-11.308h3.614v11.308zm-1.807-12.854h-.025c-1.213 0-2.001-.834-2.001-1.876 0-1.063.809-1.875 2.047-1.875 1.239 0 2.001.812 2.026 1.875 0 1.042-.787 1.876-2.047 1.876zm14.1 12.854h-3.609v-5.505c0-1.388-.496-2.337-1.735-2.337-.948 0-1.511.636-1.759 1.252-.091.223-.114.535-.114.849v5.741h-3.608s.047-9.322 0-10.285h3.608v1.458c-.007.012-.018.026-.025.039h.025v-.039c.479-.738 1.338-1.793 3.258-1.793 2.379 0 4.168 1.552 4.168 4.884v6.736z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 text-sm text-gray-400 text-center md:flex md:items-center md:justify-between">
            <p>&copy; 2024 Modular Technology. All rights reserved.</p>
            <div className="mt-4 md:mt-0">
              <a href="/privacypolicy" className="text-gray-400 hover:text-white">Privacy Policy</a> 
              <a href="/termsofservice" className="ml-4 text-gray-400 hover:text-white">Terms of Service</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
  