'use client'
import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../../apiClient';
import ReactMarkdown from 'react-markdown';

function ConversationComponent({ api_url, item, grid, gridItems, fetchGridItems, handleTrigger }) {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState("");
    const [components, setComponents] = useState([]);
    const [inputHeight, setInputHeight] = useState(48); // Base height of the input area
    const [hasTextGeneration, setHasTextGeneration] = useState(false); // State to check if there's a text generation component
    const [pendingMessage, setPendingMessage] = useState(null); // State for the pending message
    const inputRef = useRef(null);
    const conversationEndRef = useRef(null); // Ref to scroll to the bottom

    useEffect(() => {
        fetchComponents();
        checkForTextGeneration();
    }, [gridItems]);

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom when components change
    }, [components, pendingMessage]);

    const handleClearConversation = async () => {
        try {
            await apiClient.delete(`${api_url}/api/conversation-items/${item.i}/clear/`);
            setPendingMessage(null); // Clear pending message as well
            fetchGridItems(grid.id);
        } catch (error) {
            console.error('Error clearing conversation:', error);
        }
    };

    const checkForTextGeneration = () => {
        // Check if there's a text_generation item with this conversation as input
        const found = gridItems.some(gridItem => 
            gridItem.item_type === 'text_generation' && 
            gridItem.input_object_id === item.i
        );
        setHasTextGeneration(found);
    };

    const handleGenerate = () => {
        // Trigger the generate action
        handleTrigger(item, input, "generate");
        fetchGridItems();
        console.log('Generate action triggered');
    };

    const fetchComponents = async () => {
        try {
            const response = await apiClient.get(`${api_url}/api/conversation-items/${item.i}/components/`);
            setComponents(response.data);
            setPendingMessage(null); // Reset pending message after components are fetched
        } catch (error) {
            console.error('Error fetching conversation components:', error);
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.innerText);
        adjustInputHeight();
    };

    const adjustInputHeight = () => {
        if (inputRef.current) {
            const scrollHeight = inputRef.current.scrollHeight;
            const maxHeight = 3 * parseFloat(getComputedStyle(inputRef.current).lineHeight);

            if (scrollHeight <= maxHeight) {
                setInputHeight(scrollHeight);
            } else {
                setInputHeight(maxHeight);
            }
        }
    };

    const handleSend = () => {
        // Add user's input to the conversation immediately
        setComponents(prevComponents => [
            ...prevComponents,
            { sender: 'User', text: input }
        ]);

        // Show the pending AI response
        setPendingMessage({
            sender: 'AI',
            text: '...'
        });

        // Trigger the backend process
        handleTrigger(item, input);
        setInput('');
        setInputHeight(48); // Reset the input height after sending
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents the default new line behavior
            handleSend(); // Triggers the send function when Enter is pressed
        }
    };

    const scrollToBottom = () => {
        if (conversationEndRef.current) {
            conversationEndRef.current.scrollTop = conversationEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (inputRef.current && inputRef.current.innerText !== input) {
            inputRef.current.innerText = input;
        }
    }, [input]);

    return (
        <div className="flex-grow relative bg-gray-900 p-5 md:w-2/5 flex flex-col h-full rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-white">AI-Chat</h2>
                <div className="flex space-x-2">
                    {hasTextGeneration && (
                        <button
                            className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-colors duration-200"
                            onClick={handleGenerate}
                        >
                            Generate
                        </button>
                    )}
                    <button
                        className="p-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200"
                        onClick={handleClearConversation}
                    >
                        Clear
                    </button>
                </div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg shadow-inner flex-grow overflow-y-auto" style={{ maxHeight: `calc(100% - ${inputHeight}px - 60px)` }} ref={conversationEndRef}>
                {components && (
                    <>
                        {components.map((msg, index) => (
                            <div key={index} className={`mb-3 overflow-x-hidden ${msg.sender === 'AI' ? 'text-left' : 'text-right'}`}>
                                <div className={`inline-block p-3 rounded-lg ${msg.sender === 'AI' ? 'bg-purple-600 text-white' : 'bg-blue-600 text-white'}`}>
                                    <strong>{msg.sender}: </strong>
                                    <ReactMarkdown>{msg.text}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {pendingMessage && (
                    <div className="mb-3 text-left">
                        <div className="inline-block p-3 rounded-lg bg-purple-600 text-white">
                            <strong>AI: </strong>
                            <ReactMarkdown>{pendingMessage.text}</ReactMarkdown>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex mt-4">
                <div
                    ref={inputRef}
                    className="flex-grow p-3 border rounded-lg bg-gray-700 text-white focus:outline-none focus:ring focus:ring-blue-500 transition-transform duration-150 overflow-y-auto"
                    style={{ height: `${inputHeight}px`, maxHeight: '10em' }}
                    contentEditable="true"
                    onInput={handleInputChange}
                    onBlur={(e) => setInput(e.target.innerText)}
                    onKeyDown={handleKeyDown} // Add this to detect Enter key
                />
                <button
                    className="ml-2 p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition-colors duration-200"
                    onClick={handleSend}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default ConversationComponent;
