import React, { useEffect } from 'react';

const AISettingsText = ({ 
    settingItem, 
    handleBlur, 
    isSelectionMode, 
    setIsSelectionMode, 
    isContextSelectionMode, 
    setIsContextSelectionMode, 
    contexts, 
    fetchContexts, 
    setContexts, 
    handleReorderContext,
    handleRemoveContext 
}) => {
    
    return (
        <div className="p-6 w-full mt-6 bg-gray-900 rounded-lg shadow-lg">
            <div className="w-full flex justify-center items-center">
                <button
                    className="w-1/2 px-4 py-2 bg-blue-700 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors duration-200"
                    onClick={() => setIsSelectionMode(!isSelectionMode)}
                >
                    Select Input
                </button>
            </div>
            <div className="w-full mt-8">
                <p className="text-purple-300 mb-2">System Message:</p>
                <div
                    type="text"
                    onBlur={(e) => handleBlur(settingItem, e.target.innerText, "system")}
                    contentEditable="true"
                    className="w-full h-40 mt-2 px-4 py-2 bg-gray-800 text-purple-200 border border-gray-700 rounded-lg outline-none focus:ring focus:ring-blue-500"
                >
                    {settingItem.system}
                </div>
            </div>
            <div className="w-full flex justify-center items-center mt-12">
                <button
                    className="w-1/2 px-4 py-2 bg-purple-700 text-white font-semibold rounded-lg hover:bg-purple-600 transition-colors duration-200"
                    onClick={() => setIsContextSelectionMode(!isContextSelectionMode)}
                >
                    Select Context
                </button>
            </div>


            {/* Context List Section */}
            <div className="w-full mt-8">
                <p className="text-purple-300 mb-2">Contexts:</p>
                <ul className="space-y-4">
                    {contexts.map((context, index) => (
                        <li key={context.id} className="flex items-center justify-between p-4 bg-gray-800 text-white rounded-lg shadow-md">
                            <div className="flex items-center space-x-4">
                                {/* Position Indicator */}
                                <div className="text-gray-400 font-semibold">
                                    {context.position}.
                                </div>
                                {/* Context Names */}
                                <div>
                                    <p className="text-lg font-medium">
                                        {context.target_name}
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                {/* Reorder Buttons */}
                                {index > 0 && (
                                    <button 
                                        className="px-2 py-1 bg-blue-600 rounded-lg hover:bg-blue-500 transition-colors duration-200"
                                        onClick={() => handleReorderContext(context.id, 'up')}
                                    >
                                        ↑
                                    </button>
                                )}
                                {index < contexts.length - 1 && (
                                    <button 
                                        className="px-2 py-1 bg-blue-600 rounded-lg hover:bg-blue-500 transition-colors duration-200"
                                        onClick={() => handleReorderContext(context.id, 'down')}
                                    >
                                        ↓
                                    </button>
                                )}
                                {/* Remove Button */}
                                <button 
                                    className="px-2 py-1 bg-red-600 rounded-lg hover:bg-red-500 transition-colors duration-200"
                                    onClick={() => handleRemoveContext(context.id)}
                                >
                                    ✕
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>


        </div>
    );
};

export default AISettingsText;
