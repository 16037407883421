import React, { useState } from 'react';
import background from '../../images/backgrounds/neuralnetwork.jpg';
import FrontpageLayout from '../FrontpageLayout';
import Footer from '../Footer';

const featuredPost = {
  id: 1,
  title: 'We’re incredibly proud to announce we have secured $75m in Series B',
  href: '#',
  description:
    'Libero neque aenean tincidunt nec consequat tempor. Viverra odio id velit adipiscing id. Nisi vestibulum orci eget bibendum dictum. Velit viverra posuere vulputate volutpat nunc. Nunc netus sit faucibus.',
  date: 'Mar 16, 2020',
  datetime: '2020-03-16',
  author: {
    name: 'Michael Foster',
    href: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
};

const allPosts = [
  {
    id: 2,
    title: 'Boost your conversion rate',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-16',
    tag: 'Article',
    author: {
      name: 'Lindsay Walton',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 3,
    title: 'AI is transforming the world',
    href: '#',
    description:
      'A look at how AI is driving innovation across various industries and reshaping the future.',
    date: 'Mar 12, 2020',
    datetime: '2020-03-12',
    tag: 'News',
    author: {
      name: 'Sarah Connor',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1502764613149-7f1d229e2308?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 4,
    title: 'Understanding Machine Learning',
    href: '#',
    description:
      'An introductory article on the basics of machine learning and its applications.',
    date: 'Mar 15, 2020',
    datetime: '2020-03-15',
    tag: 'Article',
    author: {
      name: 'John Doe',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1541233349642-6e425fe6190e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 5,
    title: 'New AI tools for business',
    href: '#',
    description:
      'Discover the latest AI tools that can help your business grow and improve efficiency.',
    date: 'Mar 18, 2020',
    datetime: '2020-03-18',
    tag: 'News',
    author: {
      name: 'Alice Smith',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1531384443929-472073a4ef1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  // Add more posts to demonstrate scrolling...
];

export default function WhyAI() {
  const [filter, setFilter] = useState('All');
  const [sortBy, setSortBy] = useState('dateDesc'); // Default to newest first

  const filteredPosts =
    filter === 'All' ? allPosts : allPosts.filter((post) => post.tag === filter);

  const sortedPosts = filteredPosts.sort((a, b) => {
    if (sortBy === 'dateAsc') {
      return new Date(a.datetime) - new Date(b.datetime);
    }
    if (sortBy === 'dateDesc') {
      return new Date(b.datetime) - new Date(a.datetime);
    }
    // You can add popularity sorting logic here later
    return 0;
  });


  return (
    <>
      <FrontpageLayout background={background} darkMode={true} />

      <div className="bg-gray-900 text-gray-300 py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-12 px-6 sm:gap-y-16 lg:grid-cols-2 lg:px-8 mt-16">
          <article className="mx-auto w-full max-w-2xl lg:mx-0 lg:max-w-lg">
            <time
              dateTime={featuredPost.datetime}
              className="block text-sm leading-6 text-gray-500"
            >
              {featuredPost.date}
            </time>
            <h2
              id="featured-post"
              className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-4xl"
            >
              {featuredPost.title}
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-400">
              {featuredPost.description}
            </p>
            <div className="mt-4 flex flex-col justify-between gap-6 sm:mt-8 sm:flex-row-reverse sm:gap-8 lg:mt-4 lg:flex-col">
              <div className="flex">
                <a
                  href="/blogcontent"
                  aria-describedby="featured-post"
                  className="text-sm font-semibold leading-6 text-indigo-400 hover:text-indigo-300"
                >
                  Continue reading <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
              <div className="flex lg:border-t lg:border-gray-700 lg:pt-8">
                <a
                  href={featuredPost.author.href}
                  className="flex gap-x-2.5 text-sm font-semibold leading-6 text-white"
                >
                  <img
                    alt=""
                    src={featuredPost.author.imageUrl}
                    className="h-6 w-6 flex-none rounded-full bg-gray-700"
                  />
                  {featuredPost.author.name}
                </a>
              </div>
            </div>
          </article>

          <div className="mx-auto w-full max-w-2xl border-t border-gray-700 pt-12 sm:pt-16 lg:mx-0 lg:max-w-none lg:border-t-0 lg:pt-0">
            {/* Filter and Sorting */}
            <div className="mb-4 flex justify-between items-center">
              <div className="flex space-x-4">
                {['All', 'News', 'Article'].map((category) => (
                  <button
                    key={category}
                    onClick={() => setFilter(category)}
                    className={`text-sm font-semibold leading-6 ${
                      filter === category
                        ? 'text-indigo-400'
                        : 'text-gray-500 hover:text-gray-300'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <div className="flex space-x-4">
                <label htmlFor="sort" className="text-sm font-semibold leading-6 text-gray-500">
                  Sort by:
                </label>
                <select
                  id="sort"
                  name="sort"
                  className="bg-gray-800 text-gray-300 text-sm rounded px-2 py-1"
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="dateDesc">Date (Newest first)</option>
                  <option value="dateAsc">Date (Oldest first)</option>
                  <option value="popularity">Popularity</option>
                </select>
              </div>
            </div>

            {/* Scrollable Container */}
            <div className="h-[60vh] overflow-y-auto">
              <div className="-my-12 divide-y divide-gray-700">
                {sortedPosts.map((post) => (
                  <article key={post.id} className="py-12">
                    <div className="group relative max-w-xl">
                      <time
                        dateTime={post.datetime}
                        className="block text-sm leading-6 text-gray-500"
                      >
                        {post.date}
                      </time>
                      <h2 className="mt-2 text-lg font-semibold text-white group-hover:text-gray-400">
                        <a href={post.href}>
                          <span className="absolute inset-0" />
                          {post.title}
                        </a>
                      </h2>
                      <p className="mt-2 text-sm leading-6 text-gray-400">
                        {post.description}
                      </p>
                      <span className="inline-block mt-2 text-xs font-semibold text-gray-500 bg-gray-800 px-2 py-1 rounded">
                        {post.tag}
                      </span>
                    </div>
                    <div className="mt-4 flex">
                      <a
                        href={post.author.href}
                        className="relative flex gap-x-2.5 text-sm font-semibold leading-6 text-white"
                      >
                        <img
                          alt=""
                          src={post.author.imageUrl}
                          className="h-6 w-6 flex-none rounded-full bg-gray-700"
                        />
                        {post.author.name}
                      </a>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}
