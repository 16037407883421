import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../../apiClient';

const StructuredDataSettings = ({ api_url, settingItem, fetchGridItems }) => {
    const [actions, setActions] = useState([]);
    const debounceTimeout = useRef(null);  // Ref to store the debounce timer

    // Fetch actions from the backend when component mounts
    useEffect(() => {
        fetchActions();
    }, []);

    const fetchActions = async () => {
        try {
            const response = await apiClient.get(`${api_url}/api/structured_data-items/${settingItem.i}/`);
            setActions(response.data.actions || []);
        } catch (error) {
            console.error('Error fetching actions:', error);
        }
    };

    const debounceApiCall = (updatedActions) => {
        // Clear any existing timeout before setting a new one
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to delay the API call
        debounceTimeout.current = setTimeout(() => {
            sendUpdatedActionsToBackend(updatedActions);
        }, 500);  // 500ms delay before sending the request
    };

    const sendUpdatedActionsToBackend = async (updatedActions) => {
        try {
            await apiClient.post(`${api_url}/api/structured_data-items/${settingItem.i}/update-actions/`, {
                actions: updatedActions,
            });
            fetchGridItems();
        } catch (error) {
            console.error('Error updating action:', error);
        }
    };

    const updateAction = (index, field, value) => {
        // Update the local state immediately for frontend responsiveness
        const updatedActions = actions.map((action, i) =>
            i === index ? { ...action, [field]: value } : action
        );
        setActions(updatedActions);

        // Debounce the API call to minimize backend requests
        debounceApiCall(updatedActions);
    };

    const addAction = async () => {
        const newAction = { name: '', input: '', output: '', systemMessage: '' };
        const updatedActions = [...actions, newAction];
        setActions(updatedActions);

        try {
            await apiClient.post(`${api_url}/api/structured_data-items/${settingItem.i}/update-actions/`, {
                actions: updatedActions,
            });
            fetchGridItems();
        } catch (error) {
            console.error('Error adding action:', error);
        }
    };

    const removeAction = async (index) => {
        const updatedActions = actions.filter((_, i) => i !== index);
        setActions(updatedActions);

        try {
            await apiClient.delete(`${api_url}/api/structured_data-items/${settingItem.i}/update-actions/`, {
                data: { action_index: index }
            });
            fetchGridItems();
        } catch (error) {
            console.error('Error removing action:', error);
        }
    };

    return (
        <div className="p-4 bg-gray-800 rounded-lg">
            <h2 className="text-xl text-gray-200 mb-4">Custom Actions</h2>

            {/* Centered button to add new action */}
            <div className="flex justify-center">
                <button
                    onClick={addAction}
                    className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-6 rounded-lg transition duration-300"
                >
                    Add Action
                </button>
            </div>

            <div className="mt-8">
                {actions.map((action, index) => (
                    <div key={index} className="relative mb-6 p-6 bg-gray-700 rounded-lg">
                        {/* Action Name Input */}
                        <input
                            type="text"
                            placeholder={`Action Name`}
                            className="bg-gray-600 text-gray-300 px-4 py-2 rounded-md w-full mb-4"
                            value={action.name}
                            onChange={(e) => updateAction(index, 'name', e.target.value)}  // Update action locally
                        />

                        {/* Action Input Dropdown */}
                        <label className="block text-gray-400 mb-2">Action Input</label>
                        <select
                            className="bg-gray-600 text-gray-300 px-4 py-2 rounded-md w-full mb-4"
                            value={action.input}
                            onChange={(e) => updateAction(index, 'input', e.target.value)}  // Update action locally
                        >
                            <option value="">Select Input</option>
                            <option value="name">Name</option>
                            <option value="description">Description</option>
                            <option value="content">Content</option>
                        </select>

                        {/* Action Output Dropdown */}
                        <label className="block text-gray-400 mb-2">Action Output</label>
                        <select
                            className="bg-gray-600 text-gray-300 px-4 py-2 rounded-md w-full mb-4"
                            value={action.output}
                            onChange={(e) => updateAction(index, 'output', e.target.value)}  // Update action locally
                        >
                            <option value="">Select Output</option>
                            <option value="name">Name</option>
                            <option value="description">Description</option>
                            <option value="content">Content</option>
                        </select>

                        {/* System Message Input */}
                        <label className="block text-gray-400 mb-2">System Message</label>
                        <textarea
                            className="bg-gray-600 text-gray-300 px-4 py-2 rounded-md w-full"
                            rows="3"
                            placeholder="System Message"
                            value={action.systemMessage}
                            onChange={(e) => updateAction(index, 'systemMessage', e.target.value)}  // Update action locally
                        />

                        {/* Remove Action Button */}
                        <button
                            onClick={() => removeAction(index)}
                            className="absolute top-2 right-2 bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-full transition duration-300"
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StructuredDataSettings;
