import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline"

function ModuleHeader({ api_url, navigate, module }) {
    return (
        <div className="flex items-center mb-4 p-6 w-full bg-gray-800 rounded-lg shadow-lg">
            <button
                onClick={() => navigate(-1)}
                className="text-indigo-400 hover:text-indigo-600 transition-colors duration-200"
            >
                <ArrowLeftCircleIcon className="h-10 w-10" />
            </button>
            <h1 className="text-3xl font-bold ml-4 text-white">
                {module ? module.name : "Loading Module..."}
            </h1>
        </div>
    )
}

export default ModuleHeader;
