import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function Breadcrumb({ hierarchy, api_url, apiClient, fetchGrid, fetchGridItems, fetchHierarchy }) {

    const deactivateAllPages = async () => {
        try {
            const token = localStorage.getItem('token'); // Assume the token is stored in local storage
            const response = await apiClient.post(
                `${api_url}/api/page-items/deactivate-all/`,
            );
            console.log(response.data.message);
            fetchGrid();
            fetchGridItems();
            fetchHierarchy();
            // Optionally, you can handle any frontend updates here
        } catch (error) {
            console.error('Error deactivating pages:', error);
        }
    };

    
    const handleClick = async (item) => {
        try {
            console.log(`Debug: Attempting to activate PageItem with ID ${item.i}`);
            
            // First, set the active status to true for the selected page item
            const response = await apiClient.patch(`${api_url}/api/page-items/${item.i}/`, {
                active: true, // Setting the active status to true
            });
    
            console.log('Debug: Page item activated:', response.data);
    
            // After activating, wait for all necessary data to be fetched
            await Promise.all([
                fetchGrid(),         // Fetch the grid state
                fetchGridItems(),    // Fetch the grid items
                fetchHierarchy(),    // Fetch the updated hierarchy
            ]);
    
            console.log('Debug: All necessary data fetched and state updated');
        } catch (error) {
            console.error('Error activating page item:', error);
        }
    };

    return (
        <nav className="breadcrumb">
            <ul className="flex space-x-2 items-center">
                <li className="flex items-center">
                    <button
                        onClick={() => deactivateAllPages()}
                        className="text-indigo-300 hover:text-indigo-100 transition-colors duration-200 flex items-center"
                    >
                        <HomeIcon className="h-5 w-5 mr-1" />
                        Home
                    </button>
                    {hierarchy.length > 0 && (
                        <ChevronRightIcon className="h-5 w-5 text-gray-400 mx-2" />
                    )}
                </li>
                {hierarchy.map((item, index) => (
                    <li key={index} className="flex items-center">
                        <p
                            className="text-indigo-300 hover:text-indigo-100 cursor-pointer transition-colors duration-200"
                            onClick={() => handleClick(item)}
                        >
                            {item.name}
                        </p>
                        {index < hierarchy.length - 1 && (
                            <ChevronRightIcon className="h-5 w-5 text-gray-400 mx-2" />
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Breadcrumb;
