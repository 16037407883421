import ModulesAndFolders from "./base_components/ModulesAndFolders";

import MosaikLayout from "./MosaikLayout"

export default function MosaikBase({api_url}) {
    return (

        <>
        <div className="min-h-screen flex flex-col bg-gray-900">
            <MosaikLayout />
            <div className="flex-grow flex flex-col">
            <ModulesAndFolders api_url={api_url} />
            </div>
        </div>
        </>
    );
}
