'use client'
import React from 'react';
import { useState } from 'react';
import { ArrowDownCircleIcon, CheckCircleIcon, ChatBubbleLeftIcon, Bars3CenterLeftIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import Footer from '../Footer';
import background from '../../images/backgrounds/platform_net.jpg'
import neuralnet from '../../images/backgrounds/neural_net_bg.jpg'

import FrontpageLayout from '../FrontpageLayout';

import screenshot from '../../images/mosaik/mosaik_screenshot.PNG'

export default function Platform() {
    const [circleHover, setCircleHover] = useState(false)
    const incentives = [
      {
        name: 'Free Shipping',
        description: "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
      },
      {
        name: '24/7 Customer Support',
        description: 'Our AI chat widget is powered by a naive series of if/else statements. Guaranteed to irritate.',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg',
      },
      {
        name: 'Fast Shopping Cart',
        description: "Look how fast that cart is going. What does this mean for the actual experience? I don't know.",
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-fast-checkout-light.svg',
      },
    ]
  return (
    <><FrontpageLayout />
    <div className='overflow-y-auto no-scrollbar scroll-smooth'>

    <div className="relative isolate overflow-hidden min-h-screen flex flex-col justify-center p-6 md:p-14">
  <img
    alt=""
    src={background}
    className="absolute inset-0 -z-10 h-full w-full object-cover"
/>
  <div className="gradient-overlay"></div>
  <div
    aria-hidden="true"
    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
  >
    <div
      style={{
        clipPath:
          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
      }}
      className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80%] sm:w-[60%] md:w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] md:w-[72.1875rem]"
    />
  </div>

  <div className="mx-auto max-w-2xl sm:py-0 lg:py-40">
    <div className="text-center">
      <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold tracking-tight text-white">
        Mosaik
      </h1>
      <div className="w-full flex justify-center items-center">
        <p className="w-3/4 md:w-1/2 border-t-2 border-white mt-6 shadow"></p>
      </div>
      <p className="mt-6 md:mt-12 text-base md:text-xl leading-8 text-gray-100 bg-slate-900 bg-opacity-30 rounded-xl px-4 md:px-5">
        Imagine a world where the ability to create AI-powered applications is not limited to those with coding knowledge. Imagine any professional, in any field, being able to create the tools that benefit them the most - by themselves.
      </p>
      <p className="mt-4 md:mt-6 text-lg md:text-2xl leading-8 text-white font-bold">
        With Mosaik, we make this world a Reality.
      </p>
      <div className="w-full flex justify-center items-center">
        <p className="w-3/4 md:w-1/2 border-t-2 border-white mt-6 shadow"></p>
      </div>
    </div>
  </div>

  <div className="mt-8 flex justify-center">
    <div
      className="relative flex flex-col items-center justify-center w-28 md:w-36 h-28 md:h-36 rounded-full bg-slate-800 bg-opacity-50 ring-1 ring-white/10 hover:ring-white/20"
      onMouseEnter={() => setCircleHover(true)}
      onMouseLeave={() => setCircleHover(false)}
    >
      <a href="#platform-detail" className="font-semibold text-lg text-white flex flex-col items-center">
        Learn How
        <ArrowDownCircleIcon
          className={`mt-2 w-10 md:w-14 h-10 md:h-14 text-gray-100 ${circleHover ? 'text-opacity-90' : 'text-opacity-50'}`}
        />
      </a>
    </div>
  </div>

  <div
    aria-hidden="true"
    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
  >
    <div
      style={{
        clipPath:
          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
      }}
      className="relative left-[calc(50%+1rem)] sm:left-[calc(50%+3rem)] aspect-[1155/678] w-[80%] sm:w-[60%] md:w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
    />
  </div>
</div>



<div className="relative isolate overflow-hidden min-h-screen p-2 md:p-14" id="platform-detail">
  <img
    alt=""
    src={neuralnet}
    className="absolute inset-0 -z-20 h-full w-full object-cover"
/>
  <div className="absolute inset-0 -z-10 bg-opacity-90 h-full w-full bg-white"></div>


  <div className="relative z-30 mx-auto w-full px-6 lg:px-8 mt-12 md:mt-24">

  <div className="grid max-w-2xl mx-auto gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:max-w-none lg:mx-0">
  <div className="relative z-40 lg:pr-8 lg:pt-4">
    <div className="bg-white bg-opacity-60 p-4 lg:ml-72 md:p-6 rounded-lg lg:max-w-lg">
      <h2 className="text-sm sm:text-base font-semibold leading-6 sm:leading-7 text-indigo-600">Mosaik - Build your own AI App</h2>
      <p className="mt-2 text-xl sm:text-2xl md:text-3xl font-bold tracking-tight text-gray-900">Do it Better, Faster.</p>
      <p className="mt-4 sm:mt-6 text-sm sm:text-base md:text-lg leading-6 sm:leading-7 md:leading-8 text-gray-600">
        With Mosaik, you can leverage a no-code, drag-and-drop interface to build your own advanced AI applications.
      </p>
      <dl className="mt-6 sm:mt-8 md:mt-10 space-y-4 text-sm sm:text-base leading-6 sm:leading-7 text-gray-600 lg:max-w-none">
        <div className="pl-2 sm:pl-4">
          <dt className="inline font-semibold text-gray-900">AI-First Applications</dt>
          <dd className="mt-1">
            Mosaik is not a tool to speed up regular app development; it is the gateway to novel kinds of AI-powered integration and applications.
          </dd>
        </div>
        <div className="pl-2 sm:pl-4">
          <dt className="inline font-semibold text-gray-900">Anything, for Anyone</dt>
          <dd className="mt-1">
            Leverage Mosaik's intuitive interface to integrate AI into the processes that eat up most of your time; be it content generation, translation, proofreading, or many more.
          </dd>
        </div>
        <div className="pl-2 sm:pl-4">
          <dt className="inline font-semibold text-gray-900">Browse & Share</dt>
          <dd className="mt-1">
            Browse the Mosaik Marketplace for existing, Mosaik-powered applications provided by the Community - or publish your own.
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <div className="relative w-full flex justify-center lg:justify-end z-40 mt-8 sm:mt-0">
    <div className="relative w-full lg:w-[150%] lg:max-w-[120rem] lg:translate-x-1/4">
      <img
        src={screenshot}
        alt="Product screenshot"
        className="w-auto lg:h-[60vh] md:h-[40vh] sm:h-[30vh] aspect-[16/9] rounded-xl shadow-xl ring-1 border border-4 border-indigo-200 object-contain"
      />
      <p className="absolute -top-8 lg:top-0 left-0 mt-2 sm:mt-4 text-xs sm:text-sm md:text-base text-center text-gray-700 font-bold w-full">
        Mosaik is still in development. Appearance is not final.
      </p>
    </div>
  </div>
</div>

  
</div>



  <div className="relative flex justify-center mt-12 z-40 w-full">
    <div
      className="relative flex items-center justify-center w-28 md:w-36 h-28 md:h-36 rounded-full  bg-opacity-50 ring-1 ring-white/10 hover:ring-white/20"
      onMouseEnter={() => setCircleHover(true)}
      onMouseLeave={() => setCircleHover(false)}
    >
      <a href="#reach-out-mosaik" className="flex flex-col xs:text-md sm:text-md md:text-md  lg:text-lg items-center font-semibold text-lg text-gray-900 w-full">
        Reach Out
        <ArrowDownCircleIcon
          className={`mt-2 w-10 md:w-14 h-10 md:h-14 text-gray-400 ${circleHover ? 'text-opacity-90' : 'text-opacity-50'}`}
        />
      </a>
    </div>
  </div>
</div>



<div className="relative isolate overflow-hidden py-16 px-2 md:p-14" id="reach-out-mosaik">
  <img
    alt=""
    src={neuralnet}
    className="absolute inset-0 -z-20 h-full w-full object-cover"
/>
  <div className="absolute inset-0 -z-10 bg-opacity-90 h-full w-full bg-white"></div>

  <div className="bg-gray-900 py-0  md:py-32 rounded-2xl shadow">
    <div className="relative isolate flex items-center">
      <div className="mx-auto w-full  sm:px-6 lg:px-16">
        <div className="mx-auto max-w-2xl flex flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:max-w-none lg:flex-row lg:items-center lg:gap-x-16 lg:py-20 xl:gap-x-20 xl:px-20">
          <div className=" sm:h-full lg:w-1/2 sm:px-4 sm:mx-auto flex-auto">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight text-white text-center mb-8 lg:mb-12">
              Mosaik is in active development
            </h2>
            <p className="text-gray-200 text-lg md:text-xl lg:text-2xl text-center mb-8 lg:mb-16">
              Modular Technology is offering investment opportunities and early-access possibilities. Reach out now to learn more.
            </p>
          </div>

          <div className="flex lg:w-1/2 flex-col lg:flex lg:justify-center justify-between space-y-6 lg:space-y-0 lg:space-x-6">
            <div className="flex flex-col justify-center items-center text-center border border-2 border-indigo-200 rounded-lg p-4 lg:p-6 w-full lg:w-1/2">
              <ChatBubbleLeftIcon className="w-12 lg:w-16 h-12 lg:h-16 mx-auto text-white mb-4"/>
              <h3 className="text-md lg:text-lg font-medium text-gray-100 mb-2">Contact us for more Information</h3>
              <a className="w-full lg:w-2/3 h-10 lg:h-12 border border-2 border-indigo-200 rounded-lg mt-4 p-2 text-gray-100 hover:bg-indigo-400" href="/about#contact">
                Reach out Now
              </a>
            </div>         
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
          }}
          className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
        />
      </div>
    </div>
  </div>
</div>


    </div>
    <Footer></Footer></>

  );
}
