import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './frontpage/pages/home';
import UnderConstruction from './frontpage/pages/underconstruction';
import Platform from './frontpage/pages/platform';
import MosaikBase from './mosaik_frontend/MosaikBase';
import CreationBase from './mosaik_frontend/module_creation/CreationBase';
import AuthWrapper from './AuthWrapper';
import { AuthProvider } from './AuthContext';  // Import AuthProvi der
import LoginPage from './Login';
import WhyAI from './frontpage/pages/whyai';
import ServicesLandingPage from './frontpage/pages/services';
import BlogContent from './frontpage/pages/BlogContent';
import AboutUs from './frontpage/pages/About';
import PrivacyPolicy from './frontpage/pages/PrivacyPolicy';
import TermsOfService from './frontpage/pages/TermsOfService';
import ContactSubmissionsTable from './frontpage/ContactSubmissonTable';



const prod_url = "https://modulartech-backend.azurewebsites.net";
const local_url = "http://localhost:8000"

const url = prod_url

function App() {
  return (
    <AuthProvider api_url={url}>
      <Router>
        <Routes>
          <Route path="/" element={<Home api_url={url} />} />
          <Route path="/platform" element={<Platform api_url={url} />} />
          <Route path="/solutions" element={<UnderConstruction api_url={url} />} />
          <Route path="/services" element={<ServicesLandingPage api_url={url} />} />
          <Route path="/whyai" element={<WhyAI api_url={url} />} />
          <Route path="/about" element={<AboutUs api_url={url} />} />
          <Route path="/login" element={<LoginPage api_url={url} />} />
          <Route path="/newsletter" element={<UnderConstruction api_url={url} />} />
          <Route path="/learnhow" element={<UnderConstruction api_url={url} />} />
          <Route path="/bookworkshop" element={<UnderConstruction api_url={url} />} />
          <Route path="/blogcontent" element={<BlogContent api_url={url} />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy api_url={url} />} />
          <Route path="/termsofservice" element={<TermsOfService api_url={url} />} />

          {/* Protecting Mosaik routes */}
          <Route
            path="/mosaik"
            element={
              <AuthWrapper api_url={url}>
                <MosaikBase api_url={url} />
              </AuthWrapper>
            }
          />
          <Route
            path="/module/:moduleId"
            element={
              <AuthWrapper api_url={url}>
                <CreationBase api_url={url} />
              </AuthWrapper>
            }
          />
          <Route
            path="/mosaik-modules"
            element={
              <AuthWrapper api_url={url}>
                <UnderConstruction api_url={url} />
              </AuthWrapper>
            }
          />
          <Route
            path="/mosaik-marketplace"
            element={
              <AuthWrapper api_url={url}>
                <UnderConstruction api_url={url} />
              </AuthWrapper>
            }
          />
          
          <Route path="/contactformsubmissions" element={<ContactSubmissionsTable api_url={url} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
