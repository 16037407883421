import React from 'react';
import FrontpageLayout from '../FrontpageLayout';
import Footer from '../Footer';

const PrivacyPolicy = () => {
  return (
    <><FrontpageLayout></FrontpageLayout>
    <div className="w-full h-full mx-auto px-4 lg:px-96 py-28 bg-gray-900 rounded-lg shadow-lg">
      <h1 className="text-3xl font-extrabold mb-4 text-white">Privacy Policy</h1>
      <p className="text-sm text-gray-400 mb-8">Effective Date: Sep. 05 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">1. Introduction</h2>
        <p className="text-gray-300 leading-relaxed">
          Modular Technology GmbH ("we," "us," or "our") values your privacy and is committed to protecting your personal information. This Privacy Policy describes how Modular Technology (modulartech.ai) collects, uses, shares, and safeguards your information. By using our platform and services, you consent to the practices described in this policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">2. Information Collection</h2>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li><strong className="text-white">Personal Information:</strong> Such as name, email address, phone number, and payment details when you register, subscribe, or make a purchase.</li>
          <li><strong className="text-white">Usage Data:</strong> Information about how you access and use our platform, including your interactions with our AI tools and features.</li>
          <li><strong className="text-white">Device Information:</strong> Data about your device, such as IP address, browser type, operating system, and unique device identifiers.</li>
          <li><strong className="text-white">Cookies and Tracking Technologies:</strong> Information collected through cookies, web beacons, and other tracking technologies to enhance your user experience.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">3. Use of Information</h2>
        <p className="text-gray-300 leading-relaxed">
          We use the information we collect to:
        </p>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li>Provide, maintain, and improve our platform and services.</li>
          <li>Process transactions and send related information, such as purchase confirmations and invoices.</li>
          <li>Personalize your experience and the content we deliver.</li>
          <li>Send promotional communications, newsletters, updates, and other relevant information.</li>
          <li>Monitor and analyze trends, usage, and activities in connection with our platform.</li>
          <li>Enhance the security and functionality of our platform.</li>
          <li>Comply with legal obligations and enforce our terms and conditions.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">4. Sharing of Information</h2>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li><strong className="text-white">Service Providers:</strong> Third-party vendors and service providers who assist us in operating our platform and providing services to you.</li>
          <li><strong className="text-white">Business Transfers:</strong> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business.</li>
          <li><strong className="text-white">Legal Requirements:</strong> If required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
          <li><strong className="text-white">Protection of Rights:</strong> To enforce our terms and conditions and to protect our rights, privacy, safety, or property, and/or that of our users or others.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">5. Security</h2>
        <p className="text-gray-300 leading-relaxed">
          We take reasonable measures to protect the information we collect from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is completely secure. While we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">6. Data Retention</h2>
        <p className="text-gray-300 leading-relaxed">
          We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected and to comply with applicable laws. When your information is no longer needed, we will securely delete or anonymize it.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">7. Your Rights</h2>
        <p className="text-gray-300 leading-relaxed">
          Depending on your location, you may have the following rights regarding your personal information:
        </p>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li><strong className="text-white">Access:</strong> Request access to the personal information we hold about you.</li>
          <li><strong className="text-white">Correction:</strong> Request correction of inaccurate or incomplete information.</li>
          <li><strong className="text-white">Deletion:</strong> Request deletion of your personal information.</li>
          <li><strong className="text-white">Objection:</strong> Object to the processing of your personal information.</li>
          <li><strong className="text-white">Data Portability:</strong> Request a copy of your personal information in a structured, commonly used, and machine-readable format.</li>
        </ul>
        <p className="text-gray-300 leading-relaxed mt-4">
          To exercise any of these rights, please contact us at [Insert Contact Email].
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">8. Third-Party Links</h2>
        <p className="text-gray-300 leading-relaxed">
          Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to read the privacy policies of any third-party websites you visit.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">9. Changes to This Privacy Policy</h2>
        <p className="text-gray-300 leading-relaxed">
          We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated policy on our platform and updating the "Effective Date" above. Your continued use of our platform after we make changes is deemed to be acceptance of those changes.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">10. Contact Us</h2>
        <p className="text-gray-300 leading-relaxed">
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <p className="text-gray-300 leading-relaxed mt-4">
          Modular Technology <br />
          Hintere Bahnhofstrasse 79, 5000 Aarau <br />
          admin@modulartech.ai <br />
          +41 79 259 11 53
        </p>
      </section>
    </div>
      <Footer></Footer></>
  );
};

export default PrivacyPolicy;
