'use client'
import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const categories = [
    {
        name: "Layout",
        key: "layout",
        items: [
            { name: "Blocker", type: "blocker" },
            { name: "Text", type: "text" },
            { name: "Page", type: "page" },
        ],
    },
    {
        name: "User Input",
        key: "user_input",
        items: [
            { name: "Text Input", type: "text_input" },
            { name: "Button", type: "button" },
            { name: "File Upload", type: "file_upload" },
        ],
    },
    {
        name: "AI Tools",
        key: "ai_tools",
        items: [
            { name: "Text Generation", type: "text_generation" },
            { name: "Conversation", type: "conversation" },
            { name: "Image Generation", type: "image_generation" },
        ],
    },
    {
        name: "Data",
        key: "data",
        items: [
            { name: "Structured Data", type: "structured_data" },
        ],
    },
];


const Toolbox = ({setDroppingItem}) => {
    const [openCategories, setOpenCategories] = useState({
        layout: false,
        user_input: false,
        ai_tools: false,
        data: false,
    });

    const toggleCategory = (categoryKey) => {
        setOpenCategories(prevState => ({
            ...prevState,
            [categoryKey]: !prevState[categoryKey],
        }));
    };

    
    const handleDragStart = (itemType) => {
        let droppingItem = { i: "-1", itemType: itemType };
    
        switch (itemType) {
            case 'text':
                droppingItem = { ...droppingItem, w: 4, h: 1 };
                break;
            case 'image':
                droppingItem = { ...droppingItem, w: 4, h: 3 };
                break;
            case 'blocker':
                droppingItem = { ...droppingItem, w: 6, h: 1 };
                break;
            case 'text_input':
                droppingItem = { ...droppingItem, w: 8, h: 10 };
                break;
            case 'page':
                droppingItem = { ...droppingItem, w: 3, h: 1 };
                break;
            case 'button':
                droppingItem = { ...droppingItem, w: 2, h: 1 };
                break;
            case 'file_upload':
                droppingItem = { ...droppingItem, w: 6, h: 6 };
                break;
            case 'text_generation':
                droppingItem = { ...droppingItem, w: 12, h: 24 };
                break;
            case 'image_generation':
                droppingItem = { ...droppingItem, w: 12, h: 12 };
                break;
            case 'structured_data':
                droppingItem = { ...droppingItem, w: 14, h: 24 };
                break;
            case 'conversation':
                droppingItem = { ...droppingItem, w: 14, h: 30 };
                break;
            default:
                droppingItem = { ...droppingItem, w: 4, h: 1 }; // default dimensions
                break;
        }
    
        setDroppingItem(droppingItem);
    };


    return (
        <div className="p-4 h-full bg-gray-800 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-white mb-4">Toolbox</h2>
            {categories.map((category) => (
                <div key={category.key} className="mb-4">
                    <div
                        className="p-3 bg-gray-700 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-600 transition-colors duration-200"
                        onClick={() => toggleCategory(category.key)}
                    >
                        <span className="text-white">{category.name}</span>
                        {openCategories[category.key] ? (
                            <ChevronUpIcon className="h-5 w-5 text-white" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-white" />
                        )}
                    </div>
                    {openCategories[category.key] && (
                        <div className="p-3 space-y-2">
                            {category.items.map((item) => (
                                <div
                                    key={item.type}
                                    className="cursor-move p-2 bg-gray-700 rounded-md hover:bg-gray-600 transition-colors duration-200 border border-gray-600"
                                    draggable={true}
                                    onDragStart={() => handleDragStart(item.type)}
                                >
                                    <span className="text-white">{item.name}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Toolbox;
