import React from 'react';
import FrontpageLayout from '../FrontpageLayout';
import Footer from '../Footer';

const TermsOfService = () => {
  return (
    <><FrontpageLayout></FrontpageLayout><div className="w-full h-full px-4 lg:px-96 bg-gray-900 rounded-lg shadow-lg py-24">
      {/* Banner */}
      <div className="bg-yellow-500 text-gray-900 rounded-lg p-4 mb-6">
        <p className="text-center font-semibold">
          These Terms of Service are subject to change. Please check back for updates.
        </p>
      </div>

      <h1 className="text-3xl font-extrabold mb-4 text-white">Terms of Service</h1>
      <p className="text-sm text-gray-400 mb-8">Effective Date: Sep 05 2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">1. Acceptance of Terms</h2>
        <p className="text-gray-300 leading-relaxed">
          Welcome to Modular Technology GmbH (“we,” “us,” or “our”). By accessing or using our website (modulartech.ai) and platform, you (“user”) agree to comply with and be bound by these Terms of Service (“Terms”). If you do not agree with these Terms, please do not use our services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">2. Description of Services</h2>
        <p className="text-gray-300 leading-relaxed">
          Modular Technology offers a no-code platform designed for building generative AI applications, enabling users to create AI-driven apps without any coding skills. These services include, but are not limited to, our drag-and-drop interface, dynamic pages, content fields, and advanced 'relays' connecting user inputs to AI-generated outputs.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">3. User Responsibilities</h2>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li><strong className="text-white">Account Creation:</strong> You are responsible for maintaining the confidentiality of your account information, including your password, and for any activity that occurs under your account.</li>
          <li><strong className="text-white">Compliance:</strong> You agree to use our services only for lawful purposes and in compliance with all applicable laws, regulations, and guidelines.</li>
          <li><strong className="text-white">Prohibited Activities:</strong> You agree not to:
            <ul className="list-disc list-inside pl-6">
              <li>Use the platform for any unlawful or unauthorized purpose.</li>
              <li>Interfere with or disrupt the integrity or performance of the platform.</li>
              <li>Attempt to gain unauthorized access to any part of the services or related systems and networks.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">4. Payment and Subscription</h2>
        <p className="text-gray-300 leading-relaxed">
          Some of our services require payment. You agree to provide accurate and complete payment information. You authorize us to charge the applicable fees to your selected payment method. If your payment method fails, you remain responsible for any uncollected amounts.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">5. Intellectual Property</h2>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2">
          <li><strong className="text-white">Ownership:</strong> All content, features, and functionalities on our platform, including text, graphics, logos, and software, are the exclusive property of Modular Technology and are protected by intellectual property laws.</li>
          <li><strong className="text-white">User Content:</strong> You retain all rights to any content you upload or create using our platform. By uploading or creating content, you grant us a non-exclusive, royalty-free, worldwide license to use, modify, and display such content as necessary to provide our services.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">6. Termination</h2>
        <p className="text-gray-300 leading-relaxed">
          We reserve the right to suspend or terminate your access to our platform at our sole discretion, without notice or liability, for conduct that we believe violates these Terms or is harmful to other users of the platform, us, or third parties, or for any other reason.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">7. Limitation of Liability</h2>
        <p className="text-gray-300 leading-relaxed">
          To the maximum extent permitted by law, Modular Technology shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        </p>
        <ul className="list-disc list-inside text-gray-300 leading-relaxed space-y-2 pl-6">
          <li>Your use or inability to use the platform.</li>
          <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
          <li>Any bugs, viruses, or other harmful code that may be transmitted to or through our platform by any third party.</li>
          <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the platform.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">8. Indemnification</h2>
        <p className="text-gray-300 leading-relaxed">
          You agree to indemnify, defend, and hold harmless Modular Technology, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these Terms or your use of our platform.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">9. Governing Law</h2>
        <p className="text-gray-300 leading-relaxed">
          These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any legal suit, action, or proceeding arising out of, or related to, these Terms or our platform shall be instituted exclusively in the federal or state courts located in [Insert Jurisdiction].
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">10. Changes to Terms</h2>
        <p className="text-gray-300 leading-relaxed">
          We may update these Terms from time to time. If we make material changes, we will notify you by posting the updated Terms on our platform and revising the “Effective Date” above. Your continued use of our services after the posting of the updated Terms constitutes your acceptance of the changes.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-3 text-indigo-400">11. Contact Us</h2>
        <p className="text-gray-300 leading-relaxed">
          If you have any questions about these Terms, please contact us at:
        </p>
        <p className="text-gray-300 leading-relaxed mt-4">
          Modular Technology <br />
          Hintere Bahnhofstrasse 79, 5000 Aarau <br />
          admin@modulartech.ai <br />
          +41 79 259 11 53
        </p>
      </section>
    </div>
    <Footer></Footer></>
  );
};

export default TermsOfService;