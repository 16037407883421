import React from 'react';
import { Link } from 'react-router-dom';
import FrontpageLayout from '../FrontpageLayout';
import neuralnetbg from "../../images/backgrounds/neural_net_bg.jpg"


export default function UnderConstruction() {
  return (
    <><FrontpageLayout /><div className="min-h-screen flex items-center justify-center bg-gray-900 bg-opacity-90 text-white">
      <div className="text-center px-4">
        <h1 className="text-5xl font-bold mb-4">Page Under Construction</h1>
        <p className="text-lg mb-8">We're working hard to bring you this page. Please check back soon!</p>
        <Link
          to="/"
          className="inline-block bg-indigo-500 text-white font-semibold px-6 py-3 rounded-md shadow-md hover:bg-indigo-400 transition duration-300"
        >
          Go Back Home
        </Link>
      </div>
    </div>
    <img className='absolute top-0 w-full h-full -z-10 text-white'
    src={neuralnetbg}
    >
    </img>
    </>
  );
}
