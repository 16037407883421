function TextItemComponent({ item, handleBlur }) {
    return (
        <div className='text-item w-full h-full shadow-md flex justify-center items-center bg-gray-900 bg-opacity-70 rounded-lg'>
            <div
                type="text"
                onBlur={(e) => handleBlur(item, e.target.innerText, "content")}
                className='text-lg w-full h-full bg-transparent border-none outline-none text-center text-white flex justify-center items-center overflow-y-scroll'
                contentEditable="true"
            >
                {item.content}
            </div>
        </div>
    )
}

export default TextItemComponent;
