import { useState, useEffect } from 'react';
import apiClient from '../../apiClient';
import { FolderIcon, DocumentIcon, XMarkIcon, ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

export default function ModulesAndFolders({ api_url }) {
    const [folders, setFolders] = useState([]);
    const [modules, setModules] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [path, setPath] = useState([]);
    const [newFolderName, setNewFolderName] = useState('');
    const [newModuleName, setNewModuleName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchFoldersAndModules();
        if (currentFolder) {
            fetchBreadcrumbs(currentFolder);
        } 
    }, [currentFolder]);

    const fetchFoldersAndModules = async () => {
        try {
            let foldersResponse, modulesResponse;
    
            if (!currentFolder) {
                // No current folder selected (root level)
                foldersResponse = await apiClient.get(`${api_url}/api/folders/?parent=`);
                modulesResponse = await apiClient.get(`${api_url}/api/modules/?folder=null`);
            } else {
                // Current folder selected, get its children
                foldersResponse = await apiClient.get(`${api_url}/api/folders/?parent=${currentFolder.id}`);
                modulesResponse = await apiClient.get(`${api_url}/api/modules/?folder=${currentFolder.id}`);
            }
    
            // Set the folders and modules state
            setFolders(foldersResponse.data || []);
            setModules(modulesResponse.data || []);
        } catch (error) {
            console.error('Error fetching data:', error.message || error.response?.data);
        }
    };
    

    const fetchBreadcrumbs = async (folder) => {
    
        console.log("Starting breadcrumb fetch for folder:", folder);
    
        try {
            const response = await apiClient.get(`${api_url}/api/folders/${folder.id}/hierarchy/`);
            console.log("Fetched breadcrumbs:", response.data);
            setPath(response.data)
        } catch (error) {
            console.error('Error fetching parent folder:', error);
        }
    };

    const createFolder = async () => {
        if (newFolderName) {
            try {
                await apiClient.post(`${api_url}/api/folders/`, {
                    name: newFolderName,
                    parent: currentFolder ? currentFolder.id : null,
                });
                setNewFolderName('');
                fetchFoldersAndModules();  // Refresh the folders
            } catch (error) {
                console.error('Error creating folder:', error.response.data);
            }
        }
    };

    const createModule = async () => {
        if (newModuleName) {
            const folderId = currentFolder ? currentFolder.id : null;
            try {
                await apiClient.post(`${api_url}/api/modules/`, {
                    name: newModuleName,
                    folder: folderId,
                });
                setNewModuleName('');
                fetchFoldersAndModules();  // Refresh the modules
            } catch (error) {
                console.error('Error creating module:', error.response.data);
            }
        }
    };

    const handleDragEnd = async (result) => {
        const { destination, source, draggableId, type } = result;
    
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    
        if (type === 'folder') {
            if (destination.droppableId === 'folders') {
                const reorderedFolders = Array.from(folders);
                const [movedFolder] = reorderedFolders.splice(source.index, 1);
                reorderedFolders.splice(destination.index, 0, movedFolder);
                setFolders(reorderedFolders);
    
                for (let i = 0; i < reorderedFolders.length; i++) {
                    await apiClient.patch(`${api_url}/api/folders/${reorderedFolders[i].id}/`, { position: i });
                }
            } else {
                const movedFolderId = folders[source.index].id;
                const newParentId = destination.droppableId.replace('folder-', '');
                await apiClient.patch(`${api_url}/api/folders/${movedFolderId}/`, { parent: newParentId });
                fetchFoldersAndModules();
            }
        } else if (type === 'module') {
            if (destination.droppableId === 'modules') {
                const reorderedModules = Array.from(modules);
                const [movedModule] = reorderedModules.splice(source.index, 1);
                reorderedModules.splice(destination.index, 0, movedModule);
                setModules(reorderedModules);
    
                for (let i = 0; i < reorderedModules.length; i++) {
                    await apiClient.patch(`${api_url}/api/modules/${reorderedModules[i].id}/`, { position: i });
                }
            } else {
                const movedModuleId = modules[source.index].id;
                const newParentId = destination.droppableId.replace('folder-', '');
                await apiClient.patch(`${api_url}/api/modules/${movedModuleId}/`, { folder: newParentId });
                fetchFoldersAndModules();
            }
        }
    };

    const deleteItem = async (type, id) => {
        const url = type === 'folder' ? `${api_url}/api/folders/${id}/` : `${api_url}/api/modules/${id}/`;
        try {
            await apiClient.delete(url);
            fetchFoldersAndModules();
        } catch (error) {
            console.error(`Error deleting ${type}:`, error.response.data);
        }
    };


    const renderBreadcrumbs = () => (
        <nav className="flex items-center space-x-2 text-sm text-gray-400">
            <button
            onClick={() => {
                setCurrentFolder(null);
                setPath([]);
            }}  // Go back to the root folder
                className="flex items-center space-x-1 text-gray-400 hover:text-gray-200 transition-colors duration-200"
            >
                <HomeIcon className="h-5 w-5" />
                <span>Home</span>
            </button>
            {path.map((folder, index) => (
                <div key={folder.id} className="flex items-center space-x-2">
                    <ChevronRightIcon className="h-4 w-4" />
                    <button
                        onClick={() => setCurrentFolder(folder)}
                        className={`hover:text-gray-200 transition-colors duration-200 ${
                            index === path.length - 1 ? 'font-semibold text-gray-300' : 'text-gray-400'
                        }`}
                    >
                        {folder.name}
                    </button>
                </div>
            ))}
        </nav>
    );

    const handleKeyPress = (e, callback) => {
      if (e.key === 'Enter') {
        callback();
      }
    };

    return (
        <div className="container mx-auto p-8 mt-24 text-gray-200">
            <div className="flex justify-between items-center mb-8 px-6 py-8 bg-gray-800 shadow-lg rounded-lg">
                <h1 className="text-3xl font-semibold text-gray-100">Module Overview</h1>
                <div className="flex space-x-4">
                    <input
                        type="text"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        placeholder="New Folder Name"
                        onKeyPress={(e) => handleKeyPress(e, createFolder)}
                        className="bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-300 placeholder-gray-400"
                    />
                    <button
                        onClick={createFolder}
                        className="bg-blue-700 text-white px-4 py-2 rounded-lg hover:bg-blue-800 transition duration-300"
                    >
                        Create Folder
                    </button>
                    <input
                        type="text"
                        value={newModuleName}
                        onChange={(e) => setNewModuleName(e.target.value)}
                        placeholder="New Module Name"
                        onKeyPress={(e) => handleKeyPress(e, createModule)}
                        className="bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500 text-gray-300 placeholder-gray-400"
                    />
                    <button
                        onClick={createModule}
                        className="bg-purple-700 text-white px-4 py-2 rounded-lg hover:bg-purple-800 transition duration-300"
                    >
                        Create Module
                    </button>
                </div>
            </div>
            <div className="my-4">
                {renderBreadcrumbs()}
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="folders" type="folder">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
                        >
                            {folders.map((folder, index) => (
                                <Draggable key={folder.id} draggableId={`folder-${folder.id}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="relative p-6 border border-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-800 cursor-pointer"
                                            onClick={() => setCurrentFolder(folder)}
                                        >
                                            <XMarkIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteItem('folder', folder.id);
                                                }}
                                                className="absolute top-2 right-2 h-5 w-5 text-red-500 cursor-pointer z-50"
                                            />
                                            <div className="flex items-center space-x-4">
                                                <FolderIcon className="h-8 w-8 text-blue-400" />
                                                <h2 className="text-lg font-semibold text-gray-200">{folder.name}</h2>
                                            </div>
                                            <Droppable droppableId={`folder-${folder.id}`} type="module">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                        className={`absolute inset-0 rounded-lg transition-all ${
                                                            snapshot.isDraggingOver ? 'bg-blue-900 opacity-50' : 'opacity-0'
                                                        }`}
                                                    >
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <Droppable droppableId="modules" type="module">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-12"
                        >
                            {modules.map((module, index) => (
                                <Draggable key={module.id} draggableId={`module-${module.id}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="relative p-6 border border-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-800 cursor-pointer"
                                            onClick={() => navigate(`/module/${module.id}`)}
                                        >
                                            <div className="flex items-center space-x-4">
                                                <DocumentIcon className="h-8 w-8 text-purple-400" />
                                                <div>
                                                    <h2 className="text-lg font-semibold text-gray-200">{module.name}</h2>
                                                </div>
                                            </div>
                                            <XMarkIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteItem('module', module.id);
                                                }}
                                                className="absolute top-2 right-2 h-5 w-5 text-red-500 cursor-pointer z-50"
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}