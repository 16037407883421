'use client'
import React from 'react';
import apiClient from '../../../apiClient';

function PageComponent({ item, api_url, grid, fetchGrid, fetchGridItems, fetchHierarchy }) {

    const handleClick = async () => {
        try {
            console.log(`Debug: Attempting to activate PageItem with ID ${item.i}`);
            
            // First, set the active status to true for the selected page item
            const response = await apiClient.patch(`${api_url}/api/page-items/${item.i}/`, {
                active: true, // Setting the active status to true
            });
    
            console.log('Debug: Page item activated:', response.data);
    
            // After activating, wait for all necessary data to be fetched
            await Promise.all([
                fetchGrid(),         // Fetch the grid state
                fetchGridItems(),    // Fetch the grid items
                fetchHierarchy(),    // Fetch the updated hierarchy
            ]);
    
            console.log('Debug: All necessary data fetched and state updated');
        } catch (error) {
            console.error('Error activating page item:', error);
        }
    };

    return (
        <button
            className='page-item w-full h-full shadow-md flex justify-center items-center bg-purple-700 text-white font-semibold rounded-lg hover:bg-purple-600 transition-colors duration-200'
            onClick={handleClick}
        >
            <span className='mr-2'>{item.name}</span>
        </button>
    );
}

export default PageComponent;
