import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import { AuthContext } from './AuthContext';
import apiClient from './apiClient';

const AuthWrapper = ({ children, api_url }) => {
  const { isAuthenticated, authToken, refreshToken, logout } = useContext(AuthContext);
  const [authStatusChecked, setAuthStatusChecked] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (isAuthenticated()) {
        try {
          // Attempt to make an authenticated request to verify token
          await apiClient.get(`${api_url}/api/folders/`, {
            headers: { Authorization: `Bearer ${authToken}` }
          });
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const newToken = await refreshToken();
            if (!newToken) {
              logout();
            }
          }
        }
      }
      setAuthStatusChecked(true);
    };

    checkAuthStatus();
  }, [authToken, isAuthenticated, refreshToken, logout]);

  if (!authStatusChecked) {
    return <div>Loading...</div>; // Optionally render a loading state
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthWrapper;
