import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import apiClient from '../../../apiClient';
import { FaEye } from 'react-icons/fa';
import TableOverlay from './TableOverlay';

const StructuredDataComponent = ({ api_url, item, gridItems, handleTrigger }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [actions, setActions] = useState([]);  // State to hold the actions
    const [selectedRow, setSelectedRow] = useState(null);  // State to track the selected row for the overlay
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);  // State to manage overlay visibility

    useEffect(() => {
        // Fetch columns, rows, and actions from the item
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`${api_url}/api/structured_data-items/${item.i}/`);
                if (response.data.columns) {
                    setColumns(response.data.columns);
                    const sortedRows = response.data.rows.sort((a, b) => a.row_id - b.row_id);
                    setRows(sortedRows);
                    setActions(response.data.actions || []);  // Set actions from response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [api_url, item.i, gridItems]);

    // Handle editing a cell
    const handleCellChange = async (rowIndex, columnName, value) => {
        const updatedRow = { ...rows[rowIndex], [columnName]: value };
        const newRows = rows.map((row, i) => (i === rowIndex ? updatedRow : row));
        const sortedRows = newRows.sort((a, b) => a.row_id - b.row_id);
        setRows(sortedRows);
        await updateRowsInBackend(updatedRow);
    };

    const updateRowsInBackend = async (row) => {
        try {
            await apiClient.put(`${api_url}/api/structured_data-row/${row.id}/`, row);
        } catch (error) {
            console.error('Error updating row:', error);
        }
    };

    // Handle adding a new row
    const handleAddRow = async () => {
        const newRow = {
            structured_data: item.i,
            row_id: rows.length + 1,
            row_name: '',
            row_description: '',
            content: ''
        };

        try {
            const response = await apiClient.post(`${api_url}/api/structured_data-row/`, newRow);
            const newRows = [...rows, response.data];
            const sortedRows = newRows.sort((a, b) => a.row_id - b.row_id);
            setRows(sortedRows);
        } catch (error) {
            console.error('Error adding row:', error);
        }
    };

    // Handle removing a row
    const handleRemoveRow = async (rowIndex) => {
        const rowToRemove = rows[rowIndex];

        try {
            await apiClient.delete(`${api_url}/api/structured_data-row/${rowToRemove.id}/`);
            const newRows = rows.filter((_, i) => i !== rowIndex);
            const sortedRows = newRows.map((row, index) => ({
                ...row,
                row_id: index + 1, // Adjust row_id to maintain sequential order
            }));
            setRows(sortedRows);
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    // Open overlay with selected row data
    const handleOpenOverlay = (row) => {
        setSelectedRow(row);
        setIsOverlayOpen(true);
    };

    // Close overlay
    const handleCloseOverlay = () => {
        setIsOverlayOpen(false);
        setSelectedRow(null);
    };

    return (
        <>
        <div className='structured-data-item w-full h-full shadow-sm flex flex-col bg-gray-100 bg-opacity-50 p-4 rounded-lg overflow-auto'>
            {/* Action buttons container */}
            <div className="flex overflow-x-auto space-x-4 py-2 min-h-16">
                {actions.map((action, index) => (
                    <button
                        key={index}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg whitespace-nowrap"
                        title={action.name}
                        onClick={() => handleTrigger(item, action, "action")}
                    >
                        {action.name}
                    </button>
                ))}
            </div>

            <table className="min-w-full bg-gray-800 text-white rounded-lg overflow-x-hidden mt-4">
                <thead className="bg-gray-700">
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} className="p-3 text-left text-sm font-semibold uppercase tracking-wider">
                                {column.name}
                            </th>
                        ))}
                        <th className="p-3"></th> {/* Empty header for the remove button */}
                    </tr>
                </thead>
                <tbody>
                    {rows.length > 0 ? (
                        rows.map((row, rowIndex) => (
                            <tr key={rowIndex} className="border-t border-gray-700">
                                {columns.map((column, colIndex) => (
                                    <td key={colIndex} className="p-3 text-sm">
                                        {column.name === 'ID' ? (
                                            <>
                                            <div className='flex justify-center'>
                                                {row.row_id}
                                                <button
                                                    onClick={() => handleOpenOverlay(row)}
                                                    className="ml-2 text-blue-500 hover:text-blue-700"
                                                >
                                                    <FaEye />
                                                </button>

                                            </div>
                                            </>
                                        ) : column.name === 'Name' ? (
                                            <input
                                                type="text"
                                                value={row.name}
                                                onChange={(e) => handleCellChange(rowIndex, 'name', e.target.value)}
                                                className="bg-gray-700 text-white rounded p-2 w-full"
                                            />
                                        ) : column.name === 'Description' ? (
                                            <input
                                                type="text"
                                                value={row.description}
                                                onChange={(e) => handleCellChange(rowIndex, 'description', e.target.value)}
                                                className="bg-gray-700 text-white rounded p-2 w-full"
                                            />
                                        ) : column.name === 'Content' ? (
                                            <input
                                                type="text"
                                                value={row.text_content}
                                                onChange={(e) => handleCellChange(rowIndex, 'text_content', e.target.value)}
                                                className="bg-gray-700 text-white rounded p-2 w-full"
                                            />
                                        ) : null}
                                    </td>
                                ))}
                                <td className="p-3 text-center">
                                    <button
                                        onClick={() => handleRemoveRow(rowIndex)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length + 1} className="p-3 text-center text-sm">
                                {rows.length === 0 ? "No data available" : "Failed to load data"}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <button
                onClick={handleAddRow}
                className="mt-4 bg-blue-600 text-white p-2 rounded-lg"
            >
                Add Row
            </button>

        </div>
        {/* Overlay for displaying row details */}
        {isOverlayOpen && selectedRow && (
            <TableOverlay isOpen={isOverlayOpen} handleCloseOverlay={handleCloseOverlay} selectedRow={selectedRow} />
        )}

        </>
    );
};

export default StructuredDataComponent;
