import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // For GitHub flavored markdown (e.g., tables, strikethrough)

function TextGenerationComponent({ item }) {
    return (
        <div className='text-generation-item w-full h-full shadow-lg flex flex-col bg-gray-800 p-6 rounded-lg overflow-y-auto'>
            <div className='text-white prose prose-sm max-w-none prose-headings:text-white prose-p:text-white prose-strong:text-white prose-a:text-blue-400 prose-code:text-white'>
                <ReactMarkdown children={item.content} remarkPlugins={[remarkGfm]} />
            </div>
        </div>
    );
}

export default TextGenerationComponent;
