'use client'
import React, { useState, useEffect } from 'react';

import ModuleHeader from './ModuleHeader';
import ModuleGrid from './ModuleGrid';
import Toolbox from './Toolbox';
import ItemSettings from './ItemSettings';
import apiClient from '../../apiClient';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ChevronDownIcon, ChevronUpIcon, ArrowsPointingOutIcon, Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/solid';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import Breadcrumb from './Breadcrumb';
import MosaikLayout from '../MosaikLayout';


function CreationBase({api_url}) {


    const navigate = useNavigate();

    const { moduleId } = useParams();
    const [module, setModule] = useState(null)
    const [grid, setGrid] = useState(null);
    const [gridItems, setGridItems] = useState([]);
    const [settingItem, setSettingItem] = useState(null)
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [isContextSelectionMode, setIsContextSelectionMode] = useState(false);
    const [droppingItem, setDroppingItem] = useState({i:"-1", w:1, h:1})
    const [hierarchy, setHierarchy] = useState([]);
    const [contexts, setContexts] = useState([]);


    useEffect(() => {
        fetchModule();
    }, []);

    useEffect(() => {
        if(module){
          fetchGrid();
        }
    }, [module]);

    useEffect(() => {
        if(grid){
          fetchGridItems(grid.id);
        }
    }, [grid]);

    useEffect(() => {
        if(!settingItem){
          setIsSelectionMode(false)
        }
    }, [settingItem]);

    useEffect(() => {
        if (grid) {
            fetchHierarchy();
        }
    }, [grid]);


    const handleTrigger = async (item, input, trigger_type="") => {
        if(!input){
            input = ""
        }
        await apiClient.post(`${api_url}/api/trigger/`, {
            id: item.i,
            item_type: item.item_type,
            content: input,
            trigger_type: trigger_type,
        }).then(response => {
            fetchGridItems(grid.id)
        }).catch(error => {
            console.error('Error updating item:', error);
        });
    };

    const fetchContexts = async (settingItemId) => {
        try {
            const response = await apiClient.get(`${api_url}/api/contexts/`, {
                params: {
                    source_object_id: settingItemId,
                },
            });
            setContexts(response.data);
        } catch (error) {
            console.error('Error fetching contexts:', error);
        }
    };

    const fetchModule = async () => {
        try {
            const response = await apiClient.get(`${api_url}/api/modules/${moduleId}/`);
            setModule(response.data);
        } catch (error) {
            console.error('Error fetching module and grid data:', error);
        }
    };

    // Fetch the page hierarchy based on the current grid
    const fetchHierarchy = async () => {
        // Assuming you have an API endpoint to get the hierarchy or you can build it locally
        try {
            const response = await apiClient.get(`${api_url}/api/grids/${grid.id}/hierarchy/`);
            console.log(response)
            setHierarchy(response.data);
        } catch (error) {
            console.error("Error fetching hierarchy:", error);
        }
    };

    const fetchGrid = async () => {
        try {
            const response = await apiClient.get(`${api_url}/api/grids/by-module/`, {
                params: {
                    module_id: module.id,
                }
            });
            console.log('Grid data:', response.data);
            // Do something with the grid data
            setGrid(response.data)
        } catch (error) {
            console.error('Error fetching grid data:', error);
        }
    };

    const fetchGridItems = async (gridId) => {
        try {
            const response = await apiClient.get(`${api_url}/api/grids/${grid.id}/all_items/`);
            console.log('All items:', response.data);
            setGridItems(response.data)
        } catch (error) {
            console.error('Error fetching all items:', error);
        }
    };

    const handleBlur = (item, content, identifier) => {
        console.log(content)
        // Send updated text to the backend
        if(identifier === "content"){
            apiClient.patch(`${api_url}/api/${item.item_type}-items/${item.i}/`, {
                content: content
            }).then(response => {
                console.log('Text updated:', response.data);
            }).catch(error => {
                console.error('Error updating text:', error);
            });
        } else if (identifier === "system") {
            apiClient.patch(`${api_url}/api/${item.item_type}-items/${item.i}/`, {
                system: content
            }).then(response => {
                console.log('Text updated:', response.data);
            }).catch(error => {
                console.error('Error updating text:', error);
            });
        } else if (identifier === "start_text") {
            apiClient.patch(`${api_url}/api/${item.item_type}-items/${item.i}/`, {
                start_text: content
            }).then(response => {
                console.log('Text updated:', response.data);
            }).catch(error => {
                console.error('Error updating text:', error);
            });
        }
    };

    return(
        <><MosaikLayout />
        <div className="w-full h-screen flex flex-col bg-gray-900 text-gray-200 pt-16">
            <div className="flex-none p-2">
                <ModuleHeader
                    api_url={api_url}
                    navigate={navigate}
                    module={module}
                />
            </div>
        
            <div className="flex-none px-4">
                <Breadcrumb 
                    hierarchy={hierarchy} 
                    api_url={api_url} 
                    apiClient={apiClient} 
                    fetchGrid={fetchGrid} 
                    fetchGridItems={fetchGridItems} 
                    fetchHierarchy={fetchHierarchy} 
                />
            </div>
        
            <div className="flex-grow flex gap-6 p-4 overflow-hidden">
                <div className="w-3/4 h-full">
                    <ModuleGrid
                        api_url={api_url}
                        grid={grid}
                        fetchGrid={fetchGrid}
                        fetchGridItems={fetchGridItems}
                        gridItems={gridItems}
                        settingItem={settingItem}
                        fetchHierarchy={fetchHierarchy}
                        isSelectionMode={isSelectionMode}
                        isContextSelectionMode={isContextSelectionMode}
                        setIsSelectionMode={setIsSelectionMode}
                        setIsContextSelectionMode={setIsContextSelectionMode}
                        setSettingItem={setSettingItem}
                        droppingItem={droppingItem}
                        handleBlur={handleBlur}
                        fetchContexts={fetchContexts}
                        contexts={contexts}
                        handleTrigger={handleTrigger}
                        className="h-full"
                    />
                </div>

                <div className="w-1/4 h-full">
                    {settingItem ? (
                        <ItemSettings
                            api_url={api_url}
                            settingItem={settingItem}
                            handleBlur={handleBlur}
                            isSelectionMode={isSelectionMode}
                            isContextSelectionMode={isContextSelectionMode}
                            setIsSelectionMode={setIsSelectionMode}
                            setIsContextSelectionMode={setIsContextSelectionMode}
                            setSettingItem={setSettingItem}
                            grid={grid}
                            fetchGridItems={fetchGridItems}
                            fetchContexts={fetchContexts}
                            contexts={contexts}
                            setContexts={setContexts}
                            handleTrigger={handleTrigger}
                            className="bg-gray-800 p-4 rounded-lg shadow-lg h-full"
                        />
                    ) : (
                        <Toolbox
                            setDroppingItem={setDroppingItem}
                            className="bg-gray-800 p-4 rounded-lg shadow-lg h-full"
                        />
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export default CreationBase