'use client'
import React, { useState } from 'react';
import { FiUpload, FiTrash2 } from 'react-icons/fi';

function FileUploadItemComponent({ item }) {
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
            // Handle file upload logic here
        }
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
            // Handle file upload logic here
        }
    };

    const removeFile = () => {
        setSelectedFile(null);
        // Additional logic to remove the file from the server can go here
    };

    return (
        <div className='file-upload-item w-full h-full shadow-lg flex flex-col justify-center items-center bg-gray-800 rounded-lg p-6'>
            <label
                className={`flex flex-col items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer transition-colors duration-200 ${
                    isDragging ? 'border-purple-500 bg-gray-700' : 'border-purple-600 hover:border-purple-500'
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input type="file" className='hidden' onChange={handleFileChange} />
                <div className='flex flex-col items-center'>
                    <FiUpload className='w-12 h-12 text-purple-600 mb-4' />
                    <span className='text-purple-400 text-center'>
                        Drag & drop files here, or click to select files
                    </span>
                </div>
            </label>
            {selectedFile && (
                <div className='mt-4 w-full bg-gray-700 text-purple-300 text-sm p-2 rounded-lg flex justify-between items-center'>
                    <span>{selectedFile.name}</span>
                    <FiTrash2
                        className='w-5 h-5 cursor-pointer hover:text-purple-500 transition-colors duration-200'
                        onClick={removeFile}
                    />
                </div>
            )}
            <button
                className={`mt-6 py-2 px-6 bg-purple-800 text-white font-semibold rounded-lg shadow-md hover:bg-purple-700 transition-colors duration-200 ${
                    !selectedFile && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={!selectedFile}
                onClick={() => {
                    // Implement upload functionality here
                }}
            >
                Upload
            </button>
        </div>
    );
}

export default FileUploadItemComponent;
