'use client'
import React, { useState, useEffect } from 'react';

import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css'
import apiClient from '../../apiClient';

import axios from 'axios';

import { ArrowsPointingOutIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";

import TextItemComponent from "./grid_components/TextItemComponent";
import TextGenerationComponent from "./grid_components/TextGenerationItemComponent";
import TextInputComponent from "./grid_components/TextInputComponent";
import ButtonComponent from "./grid_components/ButtonComponent";
import PageComponent from "./grid_components/PageComponent";
import ImageGenerationComponent from "./grid_components/ImageGenerationItemComponent";
import FileUploadItemComponent from "./grid_components/FileUploadItemComponent";
import ConversationComponent from "./grid_components/ConversationComponent";
import StructuredDataComponent from "./grid_components/StructuredDataComponent";

function ModuleGrid({
    api_url,
    grid,
    fetchGrid,
    fetchGridItems,
    fetchHierarchy,
    gridItems,
    settingItem,
    isSelectionMode,
    isContextSelectionMode,
    setIsSelectionMode,
    setSettingItem,
    droppingItem,
    handleBlur,
    fetchContexts,
    contexts,
    handleTrigger
}) {
    


    const getContentTypeId = async (modelName) => {
        try {
            const response = await apiClient.get(`${api_url}/api/content_types/?model=${modelName}`);
            return response.data.id;
        } catch (error) {
            console.error('Error fetching content type ID:', error);
            throw error;
        }
    };
    
    const renderGridItems = () => {
        return gridItems.map((item) => {
            let content;
            switch (item.item_type) {
                case 'text':
                    content = <TextItemComponent item={item} handleBlur={handleBlur} />;
                    break;
                case 'blocker':
                    content = <div className='w-full h-full'></div>;
                    break;
                case 'button':
                    content = <ButtonComponent item={item} />;
                    break;
                case 'page':
                    content = (
                        <PageComponent
                            item={item}
                            api_url={api_url}
                            grid={grid}
                            fetchGrid={fetchGrid}
                            fetchGridItems={fetchGridItems}
                            fetchHierarchy={fetchHierarchy}
                        />
                    );
                    break;
                case 'text_input':
                    content = <TextInputComponent item={item} handleBlur={handleBlur} handleTrigger={handleTrigger} />;
                    break;
                case 'file_upload':
                    content = <FileUploadItemComponent item={item} />;
                    break;
                case 'text_generation':
                    content = <TextGenerationComponent item={item} />;
                    break;
                case 'image_generation':
                    content = <ImageGenerationComponent item={item} />;
                    break;
                case 'structured_data':
                    content = <StructuredDataComponent api_url={api_url} item={item} gridItems={gridItems} handleTrigger={handleTrigger}/>;
                    break;
                case 'conversation':
                    content = (
                        <ConversationComponent
                            api_url={api_url}
                            item={item}
                            grid={grid}
                            gridItems={gridItems}
                            fetchGridItems={fetchGridItems}
                            handleTrigger={handleTrigger}
                        />
                    );
                    break;
                default:
                    content = <span>Unknown item type</span>;
            }
    
            // Determine if the item should be highlighted or greyed out based on its type
            let overlayClass = '';
            let borderClass = '';  // Default, no special border
    
            if (settingItem) {
                if (isSelectionMode) {
                    overlayClass = 'bg-gray-400 bg-opacity-50';
                    if (settingItem.item_type === "text_generation") {
                        if (item.item_type === 'page') {
                            overlayClass = ''; // No overlay for page items
                        } else if (['text_input', 'text', 'conversation', 'structured_data'].includes(item.item_type)) {
                            overlayClass = 'bg-green-500 bg-opacity-20'; // Highlight clickable items
                        }
            
                        // Check if this item is the input for the TextGenerationItem
                        if (settingItem.input_object_id === item.i) {
                            overlayClass = 'bg-transparent';  // No overlay
                            borderClass = 'border-4 border-blue-200'; // Light blue thick border
                        }
                    }
                } else if (isContextSelectionMode) {
                    overlayClass = 'bg-gray-400 bg-opacity-50';
                    if(settingItem.i !== item.i){
                        // Allow clickability only for specified item types
                        if (item.item_type === 'page') {
                            overlayClass = ''; // No overlay for page items
                        } else if (['conversation', 'text', 'text_input', 'text_generation', 'structured_data'].includes(item.item_type)) {
                            overlayClass = 'bg-green-500 bg-opacity-20'; // Highlight clickable items
    
                        // Check if this item is already a context of the current setting item and is not the setting item itself
                        const isAlreadyContext = contexts.some(
                            context => context.target_object_id === item.i && item.i !== settingItem.i
                        );

                        if (isAlreadyContext) {
                            overlayClass = 'bg-transparent';  // No overlay
                            borderClass = 'border-4 border-blue-200'; // Light blue thick border
                        }
                        } else {
                            overlayClass = 'bg-gray-400 bg-opacity-50'; // Gray out non-clickable items
                        }

                    }
                    
                }
            }
    
            return (
                <div
                    key={item.i}
                    data-grid={item}
                    className={`flex justify-center items-center relative bg-gray-900 rounded-lg shadow-lg ${borderClass} ${
                        settingItem === item ? 'border-4 border-blue-400' : ''
                    }`}
                >
                    {overlayClass && (
                        <div
                            className={`absolute w-full h-full top-0 cursor-pointer z-50 ${overlayClass}`}
                            onClick={(e) => handleComponentClick(item)}
                        ></div>
                    )}
                    {!overlayClass && (
                        <>
                            <div className="h-5 w-5 left-1 p-1 z-50 absolute top-0">
                                <ArrowsPointingOutIcon
                                    className="h-4 w-4 react-grid-dragHandleExample cursor-move text-gray-400 hover:text-gray-200 transition-colors duration-200"
                                />
                            </div>
                            <div className="h-5 w-5 right-1 p-1 z-50 absolute top-0">
                                <Cog6ToothIcon
                                    className="h-4 w-4 cursor-pointer text-gray-400 hover:text-gray-200 transition-colors duration-200"
                                    onClick={(e) => setSettingItem(item)}
                                />
                            </div>
                        </>
                    )}
                    <div className="w-full h-full flex justify-center items-center text-white">
                        {content}
                    </div>
                </div>
            );
        });
    };
    
    const getModelName = (itemType) => {
        switch (itemType) {
            case 'text_input':
                return 'usertextinputitem';
            case 'text':
                return 'textitem';
            case 'structured_data':
                return 'structureddata';
            case 'text_generation':
                return 'textgenerationitem';
            // Add other cases here if needed
            default:
                return itemType; // Use the item type as is for other models
        }
    };

    const handleComponentClick = async (item) => {
        if (isSelectionMode) {
            try {
                const modelName = getModelName(item.item_type.toLowerCase());
    
                const isCurrentlySelected = settingItem.input_object_id === item.i;
    
                const payload = isCurrentlySelected
                    ? { input_content_type: null, input_object_id: null }
                    : {
                          input_content_type: await getContentTypeId(modelName),
                          input_object_id: item.i,
                      };
    
                // Update the TextGenerationItem via API
                await apiClient.patch(`${api_url}/api/${settingItem.item_type}-items/${settingItem.i}/`, payload);
    
                // Update the settingItem state to trigger a re-render
                setSettingItem((prev) => ({
                    ...prev,
                    input_content_type: isCurrentlySelected ? null : payload.input_content_type,
                    input_object_id: isCurrentlySelected ? null : item.i,
                }));
    
                // Fetch the updated grid items if needed
                fetchGridItems(grid.id);
            } catch (error) {
                console.error('Error updating item:', error);
            }
        } else if (isContextSelectionMode) {
            try {
                // Assuming `settingItem` is the item you're connecting from, and `item` is the item you're connecting to.
                const sourceModelName = getModelName(settingItem.item_type.toLowerCase());
                const targetModelName = getModelName(item.item_type.toLowerCase());
    
                const payload = {
                    position: 0,
                    source_content_type: await getContentTypeId(sourceModelName),
                    source_object_id: settingItem.i,
                    target_content_type: await getContentTypeId(targetModelName),
                    target_object_id: item.i,
                };
    
                // Create the new Context entry via API
                await apiClient.post(`${api_url}/api/contexts/`, payload);
    
                // Optionally, update state or UI based on the new context created
                console.log('Context created:', payload);
                fetchContexts(settingItem.i)
                
                // Fetch the updated grid items or other data if necessary
                fetchGridItems(grid.id);
            } catch (error) {
                console.error('Error creating context:', error);
            }
        }
    };
    const onDrop = (layout, item, e) => {
        console.log('Dropped item:', item);
        const newItem = {
          x: item.x,
          y: item.y,
          static: false,
        };
        console.log(droppingItem)
        if (grid) {
          const gridId = grid.id; // Get the grid ID dynamically
          console.log("grid ID", gridId)
          if (droppingItem.itemType != '') {
            apiClient.post(`${api_url}/api/${droppingItem.itemType}-items/`, {
              grid: gridId,
              item_type: droppingItem.itemType,
              x: newItem.x,
              y: newItem.y,
              w: droppingItem.w,
              h: droppingItem.h,
              static: false,
              content: 'New Item'
            }).then(response => {

              fetchGridItems(gridId)
            }).catch(error => {
              console.error('Error creating Item:', error);
            });
          }
        }
    };

    const onResizeStop = (layout, oldItem, newItem) => {
        // Update the item in the backend
        console.log(oldItem);
        apiClient.patch(`${api_url}/api/items/${oldItem.i}/`, {
            w: newItem.w,
            h: newItem.h,
            x: newItem.x,
            y: newItem.y
        }).then(response => {
            console.log('Item updated:', response.data);
            fetchGridItems(grid.id);
        }).catch(error => {
            console.error('Error updating item:', error);
        });
    };
    
    const onDragStop = (layout, oldItem, newItem) => {
        console.log('Moved item:', newItem);
        // Update the item in the backend
        apiClient.patch(`${api_url}/api/items/${oldItem.i}/`, {
            x: newItem.x,
            y: newItem.y
        }).then(response => {
            fetchGridItems(grid.id);
        }).catch(error => {
            console.error('Error updating item:', error);
        });
    };

    return (
        <div className="w-full min-h-full px-2 border-r border-gray-700 flex-grow">
            <div className=" h-[72vh] border w-[1350px] overflow-hidden border-gray-600 z-40 relative">
                <GridLayout
                className="layout min-h-full max-h-full w-full overflow-y-auto overflow-x-hidden no-scrollbar bg-gray-800 z-50 "
                layout={gridItems}
                cols={30}
                rowHeight={30}
                width={1350}
                margin={[0,0]}
                padding={[2,2]}
                isDraggable={true} 
                compactType={"vertical"}
                isDroppable={true}
                preventCollision={false}
                resizeHandles={['sw', 'nw', 'se', 'ne']}
                onDrop={onDrop}
                onResizeStop={onResizeStop}
                onDragStop={onDragStop}
                droppingItem={droppingItem}
                draggableHandle=".react-grid-dragHandleExample"
                >
                {renderGridItems()}
                    

                </GridLayout>
            </div>
        </div>
    )
}

export default ModuleGrid