'use client'
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextInputComponent({ item, handleBlur, handleTrigger }) {
    const [text, setText] = useState(item.content);
    const [expanded, setExpanded] = useState(false);

    const mainToolbarOptions = [
        [{ 'size': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ];

    const handleChange = (value) => {
        setText(value);
        handleBlur(item, value, "content");
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="text-input-item w-full h-full flex flex-col bg-gray-900 rounded-lg p-4">
            <div className='h-5/6 overflow-y-auto no-scrollbar'>
            <ReactQuill
                value={text}
                onChange={handleChange}
                className={`custom-quill ${'h-full'}`}
                modules={{
                    toolbar: mainToolbarOptions
                }}
                formats={[
                    'font', 'size',
                    'bold', 'italic', 'underline',
                    'list', 'bullet',
                    'strike', 'blockquote',
                    'indent',
                    'link', 'image', 'video'
                ]}
                theme="snow"
            />

            </div>
            <div className="flex justify-center pt-4 items-center">
                <button
                    className="bg-purple-600 hover:bg-purple-500 text-white font-semibold rounded-lg py-2 px-6 transition-colors duration-200"
                    onClick={(e) => handleTrigger(item, text)}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default TextInputComponent;
